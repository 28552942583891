import { useEffect, useState } from 'react';
import request from 'api/request';

export function useSchools() {
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    let isActive = true;
    request({ url: '/schools' }).then(({ data }) => {
      isActive && Array.isArray(data) && setSchools(data);
    });
    return () => {
      isActive = false;
    };
  }, []);

  return schools;
}

export function useSchoolCourses(school) {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    if (!school) {
      setCourses([]);
      return;
    }
    let isActive = true;
    request({ url: '/schools/courses', data: { school } }).then(({ data }) => {
      isActive && Array.isArray(data) && setCourses(data);
    });
    return () => {
      isActive = false;
    };
  }, [school]);

  return courses;
}
