import React, { useState, useEffect } from 'react';

import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import useDebounced from 'hooks/use-debounced';
import Section from 'components/content/section';
import { useCourse } from 'selectors/course-selectors';
import { updateCourse } from 'actions/course-actions';

export default function EmailDomainSection({ courseId }) {
  const course = useCourse(courseId);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [emailDomainText, setEmailDomainText] = useState('');
  const debouncedSubmit = useDebounced(updateCourse, 300);

  useEffect(() => {
    if (course) {
      setIsSwitchOn(course.canStudentsEnrollByEmail);
      setEmailDomainText(course.emailDomain);
    }
  }, [course]);

  const handleToggle = () => {
    setIsSwitchOn((isSwitchOn) => !isSwitchOn);
    updateCourse({ id: courseId, canStudentsEnrollByEmail: !isSwitchOn });
  };

  const handleChangeText = (event) => {
    setEmailDomainText(event.target.value);
    debouncedSubmit({
      id: courseId,
      emailDomain: event.target.value,
    });
  };

  if (!course) {
    return null;
  }

  return (
    <Section title="Enroll students by email domain" m={2}>
      <Typography paragraph>
        If your school or university gives out student email addresses, you may
        allow students to enroll by proving they have an organizational email
        address.
      </Typography>

      <Typography paragraph>
        When enabled, this setting authorizes anyone with a valid confirmed
        email address from a specific domain to register as a student in your
        course.
      </Typography>

      <Typography paragraph component="div">
        <FormControlLabel
          control={
            <Switch
              checked={isSwitchOn}
              onChange={handleToggle}
              color="primary"
            />
          }
          label={
            isSwitchOn ? (
              <>
                Anyone with an email address ending in &quot;
                <strong>{emailDomainText}</strong>&quot; can enroll as a student
              </>
            ) : (
              <>
                This setting is <strong>disabled</strong>
              </>
            )
          }
        />
      </Typography>

      <Collapse in={isSwitchOn}>
        <TextField
          label="Email suffix"
          helperText="Changes are saved automatically"
          value={emailDomainText}
          fullWidth
          variant="outlined"
          error={emailDomainText.length === 0}
          onChange={handleChangeText}
        />
      </Collapse>
    </Section>
  );
}
