import React, { forwardRef } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { useSchools } from 'selectors/school-selectors';

export default forwardRef(SchoolAutocomplete);

function SchoolAutocomplete({ onChange, ...props }, ref) {
  const options = useSchools();

  const handleChange = (event, value) => {
    onChange instanceof Function && onChange(value);
  };

  return (
    <Autocomplete
      ref={ref}
      options={options}
      defaultValue=""
      onChange={handleChange}
      renderInput={AutocompleteInput}
      {...props}
    />
  );
}

function AutocompleteInput({ InputProps, ...params }) {
  return (
    <TextField
      {...params}
      label="School or university"
      margin="normal"
      variant="outlined"
      fullWidth
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
      }}
    />
  );
}
