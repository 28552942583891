import React from 'react';
import { useForm } from 'react-hook-form';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import SubmitIcon from '@material-ui/icons/Done';
import { CircularProgress } from '@material-ui/core';

import { joinCourse } from 'actions/course-actions';

export default function JoinByCodeForm({ courseId, onSubmit }) {
  const { register, handleSubmit, formState, errors, setError } = useForm({
    mode: 'onChange',
  });
  const { dirty, isSubmitting } = formState;

  const handleJoin = (data) => {
    return joinCourse({ id: courseId, code: data.code }).then(
      ({ status, data: user }) => {
        switch (status) {
          case 409:
            setError('code', 'disallowed');
            break;
          case 403:
            setError('code', 'incorrect');
            break;
          case 200:
            onSubmit instanceof Function &&
              onSubmit({
                status: user.status,
                role: user.role,
              });
            break;
          default:
            setError('code', 'unknown');
        }
      }
    );
  };

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton variant="contained" type="submit">
        {isSubmitting ? (
          <CircularProgress size="1em" color="inherit" />
        ) : (
          <SubmitIcon color="primary" />
        )}
      </IconButton>
    </InputAdornment>
  );

  return (
    <form onSubmit={handleSubmit(handleJoin)}>
      <TextField
        label="Entry code"
        variant="outlined"
        margin="normal"
        fullWidth
        autoFocus
        name="code"
        error={Boolean(errors.code)}
        helperText={helperText(errors)}
        InputProps={dirty && !errors.email ? { endAdornment } : undefined}
        disabled={isSubmitting}
        inputRef={register({ required: true, minLength: 7, maxLength: 7 })}
      />
    </form>
  );
}

function helperText(errors) {
  switch (errors.code?.type) {
    case 'minLength':
    case 'maxLength':
    case 'required':
      return 'Entry codes must be 7 characters long';
    case 'incorrect':
      return 'Incorrect entry code; please contact your instructor';
    case 'disallowed':
      return 'You are not allowed to join this course right now';
    case 'unknown':
      return 'Something went wrong and you were not enrolled';
    default:
      return 'Contact your instructor for the entry code';
  }
}
