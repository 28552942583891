import { useEffect } from 'react';
import { createSelector } from 'redux-orm';
import { useSelector, useDispatch } from 'react-redux';
import orm from 'models/orm';
import request from 'api/request';
import { userFetched } from 'actions/user-actions';

export const getStaff = createSelector(orm.Course.users, (users) =>
  users.flat().filter((u) => ['staff', 'instructor'].includes(u.role))
);

export function useStaff(courseId) {
  const dispatch = useDispatch();
  const staff = useSelector((store) => getStaff(store, courseId));

  useEffect(() => {
    let isActive = true;
    request({ url: `/courses/${courseId}/staff` }).then(({ data }) => {
      if (isActive) {
        data.forEach((user) => {
          dispatch(userFetched(user));
        });
      }
    });
    return () => {
      isActive = false;
    };
  }, [courseId, dispatch]);

  return staff;
}
