import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown';
import ViewAllCoursesIcon from '@material-ui/icons/MoreHoriz';

import MainTheme from 'styles/theme';
import capitalize from 'utils/capitalize';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
  },
}));

export default function CourseDropdown({ options: courses, value: course }) {
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = useState(null);
  const closeMenu = useCallback(() => {
    setAnchorElement(null);
  }, []);

  return (
    <>
      <List component="div" disablePadding>
        <ListItem button onClick={(e) => setAnchorElement(e.currentTarget)}>
          <ListItemText
            primary={
              <Typography variant="h6" color="textSecondary">
                {course.code}
              </Typography>
            }
            secondary={
              <Typography variant="subtitle2">
                {capitalize(course.role)}
              </Typography>
            }
            disableTypography
          />
          <ExpandMoreIcon className={classes.icon} />
        </ListItem>
      </List>

      <ThemeProvider theme={MainTheme}>
        <Menu
          anchorEl={anchorElement}
          keepMounted
          open={Boolean(anchorElement)}
          onClose={closeMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          MenuListProps={{
            subheader: <ListSubheader>Your active courses</ListSubheader>,
          }}
        >
          {courses
            .filter((course) => course.status === 'active')
            .map((course) => (
              <MenuItem
                key={course.id}
                button
                component={Link}
                to={`/courses/${course.id}`}
                onClick={closeMenu}
              >
                <ListItemText primary={course.code} secondary={course.title} />
              </MenuItem>
            ))}

          <Divider />

          <MenuItem button component={Link} to="/" onClick={closeMenu}>
            <ListItemIcon>
              <ViewAllCoursesIcon color="inherit" />
            </ListItemIcon>
            <ListItemText primary="View all courses" />
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </>
  );
}
