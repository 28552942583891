import request from '../api/request';

export function createQuestion({ courseId, field, ...data }) {
  return request({
    url: `/courses/${courseId}/${field}`,
    method: 'POST',
    data,
  });
}

export function updateQuestion({ courseId, field, index, ...data }) {
  return request({
    url: `/courses/${courseId}/${field}/${index}`,
    method: 'PUT',
    data,
  });
}

export function destroyQuestion({ courseId, field, index }) {
  return request({
    url: `/courses/${courseId}/${field}/${index}`,
    method: 'DELETE',
  });
}

export function moveUpQuestion({ courseId, field, index }) {
  return request({
    url: `/courses/${courseId}/${field}/${index}/up`,
    method: 'PUT',
  });
}

export function moveDownQuestion({ courseId, field, index }) {
  return request({
    url: `/courses/${courseId}/${field}/${index}/down`,
    method: 'PUT',
  });
}
