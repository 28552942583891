import React from 'react';
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';

import UserAvatar from 'components/user/user-avatar';
import UserNames from 'components/user/user-names';
import { useAccount } from 'selectors/auth-selectors';
import CardPlaceholder from './ticket-card-placeholder';
import { FollowupButton, MarkCompletedButton } from '../ticket-action-buttons';

const useStyles = makeStyles(() => ({
  actions: {
    justifyContent: 'flex-end',
  },
}));

export default function HelpingTicketCard({ ticket }) {
  const classes = useStyles();
  const account = useAccount();

  if (!account || !ticket) {
    return <CardPlaceholder />;
  }

  const studentIds = ticket.students.map((s) => s.userId);

  return (
    <Box clone mb={2}>
      <Card elevation={3}>
        <CardHeader
          titleTypographyProps={{ variant: 'body1' }}
          avatar={<UserAvatar ids={studentIds} />}
          title={<UserNames ids={studentIds} />}
          subheader={
            <>
              Getting help from{' '}
              <UserNames ids={ticket.teachers.map((t) => t.userId)} />
            </>
          }
          action={
            <Typography variant="body2" color="textSecondary">
              <Moment date={ticket.startedAt} fromNow />
            </Typography>
          }
        />

        <CardActions className={classes.actions}>
          <FollowupButton id={ticket.id} variant="text" />
          <MarkCompletedButton id={ticket.id} variant="contained" />
        </CardActions>
      </Card>
    </Box>
  );
}
