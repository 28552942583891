import swr from 'swr';
import request from 'api/drivers/socket';
export { trigger } from 'swr';

export default function useSWR({
  method = 'get',
  url,
  params = {},
  data = {},
  ...options
}) {
  const key =
    Object.keys(params).length > 0 ? `${url}?${encodeData(params)}` : url;

  return swr(
    key,
    () =>
      request({
        method,
        url,
        data: { ...params, ...data },
      }).then(({ data }) => data),
    options
  );
}

function encodeData(data) {
  return Object.keys(data)
    .map((key) => [key, data[key]].map(encodeURIComponent).join('='))
    .sort()
    .join('&');
}
