import React, { forwardRef } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import { useSchoolCourses } from 'selectors/school-selectors';
import nameList from 'utils/name-list';

export default forwardRef(CourseAutocomplete);

function CourseAutocomplete({ school, onChange, ...props }, ref) {
  const courses = useSchoolCourses(school);

  const handleChange = (event, value) => {
    onChange instanceof Function && onChange(value);
  };

  return (
    <Autocomplete
      ref={ref}
      options={courses}
      defaultValue=""
      autoHighlight
      onChange={handleChange}
      noOptionsText="No courses found"
      renderOption={renderDropdownOption}
      renderInput={AutocompleteInput}
      getOptionLabel={(course) => `${course.code}: ${course.title}`}
      {...props}
    />
  );
}

function renderDropdownOption(course) {
  return (
    <div>
      <Typography variant="subtitle1">
        {course.code}: {course.title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Instructed by{' '}
        {nameList(course.instructors.map((i) => `${i.first} ${i.last}`))}
      </Typography>
    </div>
  );
}

function AutocompleteInput({ InputProps, ...params }) {
  return (
    <TextField
      {...params}
      label="Course name"
      margin="normal"
      variant="outlined"
      fullWidth
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
      }}
    />
  );
}
