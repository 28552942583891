import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import LookupIcon from '@material-ui/icons/Search';

import emailRegex from 'utils/email-regex';
import useQueryParams from 'hooks/use-query-params';
import LoadingButton from 'components/buttons/loading-button';
import { lookupEmail } from 'actions/email-actions';

export default function LookupEmailForm({ onSubmit, hidden }) {
  const { email } = useQueryParams();
  const { reset, register, handleSubmit, formState, errors } = useForm({
    defaultValues: { email: email ?? '' },
  });
  const { isSubmitting } = formState;

  const submitForm = useCallback(
    ({ email }) => {
      return lookupEmail(email).then(({ status, data }) => {
        reset({ email });
        if (onSubmit instanceof Function) {
          if (status === 404) {
            onSubmit({ address: email });
          } else if (status === 200) {
            onSubmit(data);
          }
        }
      });
    },
    [onSubmit, reset]
  );

  if (hidden) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(submitForm)} disabled={isSubmitting}>
      <Typography paragraph>Having trouble confirming your email?</Typography>

      <Typography paragraph>
        Input your email address below to check its confirmation status.
      </Typography>

      <TextField
        name="email"
        label="Email address"
        variant="filled"
        margin="normal"
        fullWidth
        autoFocus
        disabled={isSubmitting}
        error={Boolean(errors.email)}
        helperText={errors.email ? 'Invalid email address' : ' '}
        inputRef={register({ required: true, pattern: emailRegex })}
      />

      <Grid container justify="flex-end" spacing={2}>
        <Grid item>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            startIcon={<LookupIcon />}
            loading={isSubmitting}
          >
            Search
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}
