import React, { useState, useEffect, useCallback } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';

import { QuizQuestionForm } from './quiz-dialog-helpers';
import useScreenSize from 'hooks/use-screen-size';
import { sendFeedback } from 'actions/ticket-actions';

export default function PostInteractionDialog({
  ticketId,
  questions,
  onClose,
  ...props
}) {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const screenSize = useScreenSize();

  const isMobile = screenSize === 'xs';
  const numSteps = questions.length;
  const question = questions[step];
  const { answer, isValid } = answers[step] || {};
  const isShowingQuiz = step < questions.length;

  const handleNext = useCallback(() => {
    setStep((s) => s + 1);
  }, []);

  const handleBack = useCallback(() => {
    if (!isSubmitting) {
      setStep((s) => s - 1);
    }
  }, [isSubmitting]);

  const handleChange = useCallback(
    (answer, isValid) => {
      setAnswers((answers) => {
        answers = [...answers];
        answers[step] = { question: questions[step], answer, isValid };
        return answers;
      });
    },
    [questions, step]
  );

  const handleClose = useCallback(() => {
    if (onClose instanceof Function) {
      setStep(0);
      setIsSubmitting(false);
      setAnswers([]);
      onClose();
    }
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
  }, []);

  useEffect(() => {
    if (!isSubmitting) {
      return;
    }
    let isActive = true;
    sendFeedback({ ticketId, answers })
      .then(() => {
        isActive && handleClose();
      })
      .finally(() => {
        isActive && setIsSubmitting(false);
      });
    return () => {
      isActive = false;
    };
  }, [isSubmitting, ticketId, handleClose, answers]);

  return (
    <Dialog
      fullWidth
      scroll="paper"
      fullScreen={isMobile}
      onClose={handleClose}
      {...props}
    >
      <DialogTitle disableTypography>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <CircularProgress
              variant="static"
              value={(step / numSteps) * 100}
              color="secondary"
            />
          </Grid>

          <Grid item xs>
            <Typography variant="subtitle1">
              Leaving feedback helps improve office hours
            </Typography>
            <Typography variant="subtitle2">
              {isShowingQuiz
                ? `Question ${step + 1} of ${numSteps}`
                : 'Ready to submit'}
            </Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      {isShowingQuiz ? (
        <QuizQuestionForm
          question={question}
          answer={answer}
          onChange={handleChange}
          onSubmit={handleNext}
          isSubmitDisabled={question.isRequired && !isValid}
        />
      ) : (
        <DialogContent>
          <Box mb={4}>
            <Typography variant="h5" align="center">
              Ready to submit feedback?
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography paragraph>
              Thank you for taking the time to submit feedback about your
              experience.
            </Typography>
            <Typography paragraph>
              Your input helps make office hours better for everyone.
            </Typography>
          </Box>
        </DialogContent>
      )}

      <MobileStepper
        variant={isMobile ? 'dots' : 'progress'}
        steps={numSteps + 1}
        position="static"
        activeStep={step}
        style={{ backgroundColor: 'white' }}
        backButton={
          <Button
            onClick={handleBack}
            disabled={step === 0 || isSubmitting}
            color="primary"
            startIcon={<BackIcon />}
          >
            Back
          </Button>
        }
        nextButton={
          <Button
            onClick={isShowingQuiz ? handleNext : handleSubmit}
            disabled={
              (isShowingQuiz && question.isRequired && !isValid) || isSubmitting
            }
            color="primary"
            variant="contained"
            endIcon={
              isSubmitting ? (
                <CircularProgress size="1em" color="inherit" />
              ) : isShowingQuiz ? (
                <NextIcon />
              ) : (
                <CheckIcon />
              )
            }
          >
            {isShowingQuiz ? 'Next' : 'Submit'}
          </Button>
        }
      />
    </Dialog>
  );
}
