import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import FeedbackIcon from '@material-ui/icons/Feedback';

import { useAccount } from 'selectors/auth-selectors';
import { useCourse } from 'selectors/course-selectors';

import FeedbackDialogButton from './feedback-dialog-button';

export default function FeedbackListItem({ ticket }) {
  const course = useCourse(ticket.courseId);
  const account = useAccount();

  if (!course || !account) {
    return null;
  }

  const participant = [...ticket.students, ...ticket.teachers].find(
    (x) => x.accountId === account.id
  );

  if (!participant) {
    return null;
  }

  if (participant.hasFeedback) {
    return (
      <ListItem>
        <ListItemIcon>
          <FeedbackIcon color="primary" />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2">
            You left feedback for this interaction
          </Typography>
        </ListItemText>
      </ListItem>
    );
  } else {
    return (
      <ListItem>
        <ListItemIcon>
          <FeedbackIcon color="primary" />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2">
            You have not left feedback for this interaction
          </Typography>

          <Typography variant="body2">
            <FeedbackDialogButton
              ticketId={ticket.id}
              prompts={course[`${participant.role}Feedback`]}
            />
          </Typography>
        </ListItemText>
      </ListItem>
    );
  }
}
