import React from 'react';
import Placeholder from 'react-placeholder';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

export default function TicketCardPlaceholder() {
  return (
    <Box mb={2} clone>
      <Card elevation={3}>
        <CardHeader
          avatar={<Placeholder type="media" showLoadingAnimation />}
          title={<Placeholder type="text" showLoadingAnimation rows={2} />}
        />
        <CardContent>
          <Placeholder type="text" rows={2} showLoadingAnimation />
        </CardContent>
      </Card>
    </Box>
  );
}
