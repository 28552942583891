import React, { useCallback } from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { cancelTicket } from 'actions/ticket-actions';
import ConfirmDialog from 'components/dialogs/confirm';

import HelpDialog from './help-dialog';
import WaitingQueueInfo from './waiting-queue-info';

export default function HandCardWaiting({ ticket }) {
  const { id, courseId } = ticket;

  const handleCancelClick = useCallback(() => {
    return cancelTicket(id);
  }, [id]);

  return (
    <Card variant="outlined">
      <CardContent>
        <WaitingQueueInfo ticketId={id} courseId={courseId} />
        <Typography variant="body2" color="textSecondary" component="p">
          A help staff member will be available to help soon. Stay near the
          designated office hours area and keep working while you wait.
          Regularly check back here so you know when to be ready.
        </Typography>
      </CardContent>

      <CardActions>
        <ConfirmDialog
          title="Leave the waitlist?"
          content="You will be removed from the waitlist and your request for help will be canceled. If you raise your hand again, you'll be placed back at the end of the line. Is this okay?"
          buttonProps={{ color: 'secondary', variant: 'contained' }}
          buttonText="Cancel request"
          acceptText="Leave waitlist"
          acceptProps={{ color: 'secondary', variant: 'contained' }}
          rejectText="I'm still waiting"
          rejectProps={{ color: 'primary' }}
          onConfirm={handleCancelClick}
        />

        <HelpDialog currentStep={2} />
      </CardActions>
    </Card>
  );
}
