import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import useScreenSize from 'hooks/use-screen-size';
import QuestionField from './fields/question-field';

export function QuizHeader({
  step,
  numSteps,
  onCancel,
  title,
  titleEntity = 'Question',
}) {
  return (
    <DialogTitle disableTypography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <CircularProgress
            variant="static"
            value={(step / numSteps) * 100}
            color="secondary"
          />
        </Grid>

        <Grid item xs>
          <Typography variant="subtitle1">{title}</Typography>
          {step + 1 <= numSteps && (
            <Typography variant="subtitle2">
              {titleEntity} {step + 1} of {numSteps}
            </Typography>
          )}
        </Grid>

        <Grid item>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
}

export function QuizQuestionForm({
  question,
  answer,
  onChange,
  onSubmit,
  isFieldDisabled,
  isSubmitDisabled,
}) {
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isSubmitDisabled && onSubmit instanceof Function) {
      onSubmit(event);
    }
  };

  return (
    <DialogContent>
      <Box mb={3}>
        <Typography variant="h5">{question.prompt}</Typography>
      </Box>

      <Box mb={3}>
        <form onSubmit={handleSubmit} disabled={isSubmitDisabled}>
          <QuestionField
            question={question}
            answer={answer}
            onChange={onChange}
            isDisabled={isFieldDisabled}
          />
        </form>
      </Box>
    </DialogContent>
  );
}

export function QuizActions({
  step,
  numSteps,
  isSubmitting,
  onNext,
  isNextDisabled,
  NextIcon = KeyboardArrowRight,
  nextButtonText = 'Next',
  onBack,
  isBackDisabled,
  BackIcon = KeyboardArrowLeft,
  backButtonText = 'Back',
}) {
  const isMobile = useScreenSize() === 'xs';

  return (
    <MobileStepper
      variant={isMobile ? 'dots' : 'progress'}
      steps={numSteps}
      position="static"
      activeStep={step}
      style={{ backgroundColor: 'white' }}
      backButton={
        <Button
          onClick={onBack}
          disabled={isBackDisabled || isSubmitting}
          color="primary"
          startIcon={<BackIcon />}
        >
          {backButtonText}
        </Button>
      }
      nextButton={
        <Button
          onClick={onNext}
          disabled={isNextDisabled || isSubmitting}
          color="primary"
          variant="contained"
          endIcon={
            isSubmitting ? (
              <CircularProgress size="1em" color="inherit" />
            ) : (
              <NextIcon />
            )
          }
        >
          {nextButtonText}
        </Button>
      }
    />
  );
}
