import request from 'api/request';

export function lookupEmail(email) {
  return request({ url: '/emails/lookup', method: 'POST', data: { email } });
}

export function resendConfirmation(email) {
  return request({
    url: '/emails/resend',
    method: 'PUT',
    data: { email },
  });
}

export function confirmEmail({ email, token }) {
  return request({
    url: '/emails/confirm',
    method: 'PUT',
    data: { email, token },
  });
}
