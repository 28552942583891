import request from 'api/request';
import socketRequest from 'api/drivers/socket';
import { courseFetched } from 'actions/course-actions';
import { chatFetched } from 'actions/chat-actions';
import { ticketFetched } from 'actions/ticket-actions';

export function fetchCourses() {
  return (dispatch) => {
    return request({ url: '/courses' }).then(({ data: courses }) => {
      courses.forEach((course) => dispatch(courseFetched(course)));
    });
  };
}

export function fetchThreads() {
  return (dispatch) => {
    return request({ url: '/chat/threads' }).then(({ data }) => {
      data.forEach((chat) => dispatch(chatFetched(chat)));
    });
  };
}

export function fetchActiveTickets() {
  return (dispatch) => {
    return request({ url: '/tickets/active' }).then(({ data: tickets }) => {
      tickets.forEach((ticket) => dispatch(ticketFetched(ticket)));
    });
  };
}

export function fetchMissingTickets() {
  return (dispatch) => {
    return request({ url: '/tickets/missing' }).then(({ data: tickets }) => {
      tickets.forEach((ticket) => dispatch(ticketFetched(ticket)));
    });
  };
}

export function subscribeToSocketEvents() {
  return () => socketRequest({ url: '/subscribe/all', method: 'POST' });
}

export default function syncSubscriptions() {
  return (dispatch) => {
    return Promise.all([
      dispatch(subscribeToSocketEvents()),
      dispatch(fetchActiveTickets()),
      dispatch(fetchMissingTickets()),
      dispatch(fetchThreads()),
      dispatch(fetchCourses()),
    ]);
  };
}
