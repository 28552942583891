import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useMyRecentTickets } from 'selectors/ticket-selectors';
import AppBar from 'components/app-bar/app-bar';
import Section from 'components/content/section';

import TicketCard from '../waitlist/ticket-card/ticket-card';
import OfficeHoursMenu from '../menu/office-hours-menu';

export default function HistoryPage() {
  const params = useParams();
  const courseId = Number(params.courseId);
  const tickets = useMyRecentTickets(courseId);

  return (
    <AppBar
      title="Recent activity"
      menu={<OfficeHoursMenu courseId={courseId} />}
    >
      <Box clone p={2}>
        <Paper>
          <Section title="Recent activity">
            {tickets.map((ticket) => (
              <TicketCard key={ticket.id} ticket={ticket} />
            ))}

            {tickets.length === 0 && (
              <Typography paragraph>
                After you&apos;ve been to office hours, your most recent
                activity will appear here.
              </Typography>
            )}
          </Section>
        </Paper>
      </Box>
    </AppBar>
  );
}
