import React from 'react';
import moment from 'moment';
import Truncate from 'react-truncate';
import { Link, useParams } from 'react-router-dom';
import Placeholder from 'react-placeholder';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import UserAvatar from 'components/user/user-avatar';
import { useUser } from 'selectors/user-selectors';

export default function ThreadListItem({ userId, body, updatedAt }) {
  const user = useUser(userId);
  const params = useParams();
  const courseId = Number(params.courseId);
  const activeUserId = Number(params.userId);

  if (!user) {
    return (
      <ThreadListItemPlaceholder
        courseId={courseId}
        userId={userId}
        activeUserId={activeUserId}
      />
    );
  }

  return (
    <ListItem
      button
      alignItems="flex-start"
      component={Link}
      to={`/courses/${courseId}/chat/${userId}`}
      selected={activeUserId === userId}
    >
      <ListItemAvatar>
        <UserAvatar id={userId} />
      </ListItemAvatar>

      <ListItemText>
        <Typography>
          {user.first} {user.last}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          <Truncate trimWhitespace>
            {moment(updatedAt).fromNow()} &mdash; {body}
          </Truncate>
        </Typography>
      </ListItemText>
    </ListItem>
  );
}

function ThreadListItemPlaceholder({ courseId, userId, activeUserId }) {
  return (
    <ListItem
      button
      alignItems="flex-start"
      component={Link}
      to={`/courses/${courseId}/chat/${userId}`}
      selected={activeUserId === userId}
    >
      <Placeholder type="media" rows={3} showLoadingAnimation children={null} />
    </ListItem>
  );
}
