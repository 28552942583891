import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'redux-orm';
import orm from 'models/orm';
import { fetchThread } from 'actions/chat-actions';

/**
 * Gets one or more chat messages
 */
export const getChat = createSelector(orm.Chat);

export function useChatMessage(id) {
  return useSelector((store) => getChat(store, id));
}

export function useChatMessages() {
  return useSelector(getChat);
}

/**
 * Gets the chat thread between the authenticated user and a particular userId
 */
export const getChatThread = createSelector(
  orm,
  orm.User.course,
  (store, userId) => userId,
  (session, course, userId) => {
    const accountUserId = course?.userId;
    return session.Chat.all()
      .toModelArray()
      .filter(
        ({ senderId, recipientId }) =>
          (senderId === userId && recipientId === accountUserId) ||
          (recipientId === userId && senderId === accountUserId)
      )
      .sort(newestFirst);
  }
);

export function useChatThread(userId) {
  const dispatch = useDispatch();
  const thread = useSelector((store) => getChatThread(store, userId));

  useEffect(() => {
    dispatch(fetchThread(userId));
  }, [dispatch, userId]);

  return thread;
}

/**
 * Gets the list of loaded chat threads for a particular userId
 */
export const getChatThreads = createSelector(
  orm.User.chatsSent,
  orm.User.chatsReceived,
  (sent, received) => {
    const threads = {};
    sent.forEach((chat) => {
      threads[chat.recipientId] = threads[chat.recipientId] || [];
      threads[chat.recipientId].push(chat);
    });
    received.forEach((chat) => {
      threads[chat.senderId] = threads[chat.senderId] || [];
      threads[chat.senderId].push(chat);
    });
    return Object.values(threads)
      .map((thread) => thread.sort(newestFirst))
      .map((thread) => thread[0])
      .sort(newestFirst);
  }
);

export function useChatThreads(userId) {
  return useSelector((store) => getChatThreads(store, userId));
}

/**
 * Sorting functions
 */
function newestFirst(a, b) {
  return new Date(b.sentAt) - new Date(a.sentAt);
}
