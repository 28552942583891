import React, { useState, useEffect } from 'react';

import Typography from '@material-ui/core/Typography';

import Section from 'components/content/section';
import DropdownSelect from 'components/inputs/dropdown-select';

import { useCourse } from 'selectors/course-selectors';
import { updateCourse } from 'actions/course-actions';

const options = [
  {
    value: 'required',
    text: 'Required for staff and students to use the waitlist',
  },
  {
    value: 'waitlist',
    text: 'Required for students to use the waitlist',
  },
  {
    value: 'optional',
    text: 'Enabled, but optional',
  },
  {
    value: 'disabled',
    text: 'Disabled completely',
  },
];

export default function ShiftPolicySection({ courseId }) {
  const course = useCourse(courseId);
  const shiftPolicy = course?.shiftPolicy;
  const [value, setValue] = useState(shiftPolicy ?? 'waitlist');

  useEffect(() => {
    if (shiftPolicy) {
      setValue(shiftPolicy);
    }
  }, [shiftPolicy]);

  const handleChange = (newValue) => {
    setValue(newValue);
    updateCourse({ id: courseId, shiftPolicy: newValue });
  };

  if (!course) {
    return null;
  }

  return (
    <Section title="Shift tracking">
      <Typography paragraph>
        My Digital Hand offers a shift management feature, which allows
        instructors to track when staff members clock in and clock out for their
        office hours shifts. If enabled, this feature also indicates to students
        which staff members are available to help, and for how long they will be
        available.
      </Typography>

      <Typography paragraph>
        When the shift feature is required for students, the office hours
        waitlist will be disabled unless a staff member is currently working an
        active shift.
      </Typography>

      <Typography paragraph>
        When the shift feature is required for staff, staff members will be
        unable to respond to student help requests unless they start an active
        shift themselves.
      </Typography>

      <DropdownSelect
        onChange={handleChange}
        value={value}
        options={options}
        title="Shift tracking is"
      />
    </Section>
  );
}
