import React, { useState, useEffect } from 'react';
import pluralize from 'pluralize';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Section from '../../../../components/content/section';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import { useCourse } from '../../../../selectors/course-selectors';
import { updateCourse } from '../../../../actions/course-actions';
import capitalize from '../../../../utils/capitalize';

export default function FeedbackRequiredSection({ courseId, field }) {
  const course = useCourse(courseId);
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const roleName = field === 'studentFeedback' ? 'student' : 'staff';
  const requiredFieldName =
    field === 'studentFeedback'
      ? 'isStudentFeedbackRequired'
      : 'isTeacherFeedbackRequired';

  useEffect(() => {
    if (course) {
      setIsSwitchOn(course[requiredFieldName]);
    }
  }, [course, requiredFieldName]);

  const handleToggle = () => {
    setIsSwitchOn((isSwitchOn) => !isSwitchOn);
    updateCourse({ id: courseId, [requiredFieldName]: !isSwitchOn });
  };

  if (!course) {
    return null;
  }

  return (
    <Section title={`Require ${roleName} feedback`} m={2}>
      <Typography paragraph>
        If this setting is enabled, {pluralize(roleName)} will be locked out of
        using the office hours feature of My Digital Hand until they have filled
        out the feedback form for all prior office hours interactions.
      </Typography>

      <Typography paragraph component="div">
        <FormControlLabel
          control={
            <Switch
              checked={isSwitchOn}
              onChange={handleToggle}
              name="checkedB"
              color="primary"
            />
          }
          label={
            isSwitchOn ? (
              <>
                {pluralize(capitalize(roleName))} are required to give feedback
              </>
            ) : (
              <>{pluralize(capitalize(roleName))} can skip giving feedback</>
            )
          }
        />
      </Typography>
    </Section>
  );
}
