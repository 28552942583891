import React from 'react';

import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';

import ExpandIcon from '@material-ui/icons/ExpandMore';

import CourseCard from './course-card';

export default function InactiveCoursesSection({ courses }) {
  courses = courses.filter(
    (course) => course.status === 'archived' && course.role
  );

  if (courses.length === 0) {
    return '';
  } else {
    return (
      <Box clone mb={2}>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandIcon />}>
            <Typography variant="h5">
              Inactive courses ({courses.length})
            </Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <Box>
              {courses.map((course) => (
                <CourseCard course={course} key={course.id} />
              ))}
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Box>
    );
  }
}
