import React, { useState, useEffect } from 'react';
import pluralize from 'pluralize';

import Collapse from '@material-ui/core/Collapse';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import useDebounced from 'hooks/use-debounced';
import { updateCourse } from 'actions/course-actions';
import { useCourse } from 'selectors/course-selectors';

import TimerActionSelect from './timer-action-select';

export default function TimerForm({ courseId }) {
  const course = useCourse(courseId);
  const [actionValue, setActionValue] = useState('enabled');
  const [durationValue, setDurationValue] = useState(10);
  const debouncedUpdate = useDebounced(updateCourse, 500);

  useEffect(() => {
    if (course) {
      setActionValue(course.timerAction);
      setDurationValue(course.timerDuration);
    }
  }, [course]);

  const onTimerChange = (event) => {
    const { value } = event.target;
    if (value > 0) {
      setDurationValue(value);
      return debouncedUpdate({ id: courseId, timerDuration: value });
    }
  };

  const onActionChange = (newActionValue) => {
    setActionValue(newActionValue);
    return debouncedUpdate({ id: courseId, timerAction: newActionValue });
  };

  if (!course) {
    return null;
  }

  return (
    <>
      <TimerActionSelect value={actionValue} onChange={onActionChange} />

      <Collapse in={actionValue !== 'disabled'}>
        <TextField
          label='"Long" interaction length'
          type="number"
          variant="outlined"
          margin="normal"
          helperText="Changes are saved automatically"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {pluralize('minute', durationValue)}
              </InputAdornment>
            ),
          }}
          value={durationValue}
          error={durationValue <= 0}
          onChange={onTimerChange}
        />
      </Collapse>
    </>
  );
}
