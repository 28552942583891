import React, { useState, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { useCourse } from 'selectors/course-selectors';
import PreInteractionDialog from 'components/dialogs/quizzes/pre-interaction-dialog';

import HelpDialogButton from './help-dialog';

export default function HandCardDown({ courseId }) {
  const course = useCourse(courseId);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  if (!course) {
    return null;
  }

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Typography gutterBottom variant="h5">
            Your hand is down
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {course.status === 'archived'
              ? 'This course has been archived by your instructor, and you can no longer raise your hand.'
              : "Raise your hand to be added to the office hours waitlist. If other students arrived before you, they'll be helped first."}
          </Typography>
        </CardContent>

        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={openDialog}
            disabled={course.status === 'archived'}
          >
            Get help now
          </Button>

          <HelpDialogButton />
        </CardActions>
      </Card>

      <PreInteractionDialog
        courseId={courseId}
        isOpen={isDialogOpen}
        onClose={closeDialog}
      />
    </>
  );
}
