import request from 'api/request';

export function courseFetching(id) {
  return {
    type: 'COURSE_FETCHING',
    payload: id,
  };
}

export function courseFetched(payload) {
  return {
    type: 'COURSE_FETCHED',
    payload,
  };
}

export function courseCreated(payload) {
  return {
    type: 'COURSE_CREATED',
    payload,
  };
}

export function courseChanged(payload) {
  return {
    type: 'COURSE_CHANGED',
    payload,
  };
}

export function courseDestroyed(id) {
  return {
    type: 'COURSE_DESTROYED',
    payload: id,
  };
}

const promises = {};
export function fetchCourse(id) {
  return (dispatch) => {
    if (id in promises) {
      return promises[id];
    }
    dispatch(courseFetching(id));
    const promise = request({ url: `/courses/${id}` })
      .then(({ data }) => {
        dispatch(courseFetched(data));
      })
      .finally(() => {
        delete promises[id];
      });
    promises[id] = promise;
    return promise;
  };
}

export function createCourse(data) {
  return request({ url: `/courses`, method: 'POST', data });
}

export function joinCourse({ id, ...data }) {
  if (id) {
    return request({ url: `/courses/${id}/join`, method: 'POST', data });
  } else {
    return request({ url: '/courses/join', method: 'POST', data });
  }
}

export function updateCourse({ id, ...data }) {
  return request({ url: `/courses/${id}`, method: 'PUT', data });
}

export function archiveCourse(id) {
  return request({ url: `/courses/${id}/archive`, method: 'POST' });
}

export function activateCourse(id) {
  return request({ url: `/courses/${id}/activate`, method: 'POST' });
}

export function destroyCourse(id) {
  return request({ url: `/courses/${id}`, method: 'DELETE' });
}

export function regenerateCourseCode({ id, field }) {
  return request({ url: `/courses/${id}/regenerate/${field}`, method: 'PUT' });
}

export function updateUserRole({ courseId, userIds, role }) {
  return request({
    url: `/courses/${courseId}/users`,
    method: 'PUT',
    data: { ids: userIds, role },
  });
}

export function addUsersByEmail({ courseId, roster, role }) {
  return request({
    url: `/courses/${courseId}/invitations`,
    method: 'POST',
    data: { roster, role },
  });
}

export function removeUsers({ courseId, userIds }) {
  return request({
    url: `/courses/${courseId}/users`,
    method: 'DELETE',
    data: { ids: userIds },
  });
}

let codePromise = null;
export function fetchEntryCodes(courseId) {
  return (dispatch) => {
    if (!(codePromise instanceof Promise)) {
      codePromise = request({ url: `/courses/${courseId}/codes` })
        .then(({ data }) => {
          dispatch(courseChanged(data));
        })
        .finally(() => {
          codePromise = null;
        });
    }
    return codePromise;
  };
}
