import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { createSelector as createOrmSelector } from 'redux-orm';
import { getSelf } from './auth-selectors';
import { fetchTicket } from 'actions/ticket-actions';
import orm from 'models/orm';

/**
 * Most general selector; with no arguments passed, gets all tickets in the
 *   store; or with an id or array of ids, gets selected tickets from the store
 */
export const getTickets = createOrmSelector(orm.Ticket);

export function useTickets() {
  return useSelector(getTickets);
}

export function useTicket(id) {
  const dispatch = useDispatch();
  const ticket = useSelector((store) => getTickets(store, id));
  if (Number.isInteger(id) && !ticket) {
    dispatch(fetchTicket(id));
  }
  return ticket;
}

/**
 * Gets "waiting", "calling", or "helping" tickets from the store, optionally
 *   filtered for a particular course
 */
export const getActiveTickets = createOrmSelector(
  orm.Course.tickets,
  (tickets) =>
    tickets
      .flat()
      .filter((t) => ['waiting', 'calling', 'helping'].includes(t.status))
      .sort(oldestFirst)
);

export function useActiveTickets(...args) {
  return useSelector((state) => getActiveTickets(state, ...args));
}

/**
 * Gets "waiting" and "calling" tickets from the store, optionally filtered for
 *   a particular course
 */
export const getWaitingTickets = createOrmSelector(
  orm.Course.tickets,
  (tickets) =>
    tickets
      .flat()
      .filter((t) => ['waiting', 'calling'].includes(t.status))
      .sort(oldestFirst)
);

export function useWaitingTickets(...args) {
  return useSelector((state) => getWaitingTickets(state, ...args));
}

/**
 * Gets all tickets in which the authenticated user is actively participating,
 *   optionally filtered for a particular course
 */
export const getMyActiveTickets = createSelector(
  getActiveTickets,
  getSelf,
  (tickets, self) =>
    tickets.filter((t) =>
      t.teachers.some((u) => u.accountId === self.id && u.status === 'active')
    )
);

export function useMyActiveTickets() {
  return useSelector(getMyActiveTickets);
}

/**
 * Gets only "helping" tickets from the store, optionally filtered for a
 *   particular course
 */
export const getHelpingTickets = createSelector(getActiveTickets, (tickets) =>
  tickets.filter((t) => t.status === 'helping')
);

/**
 * Gets only "helping" tickets from the store for which the authenticated
 *   user is an active teacher, optionally filtered for a particular course
 */
export const getMyHelpingTickets = createSelector(
  getHelpingTickets,
  getSelf,
  (tickets, self) =>
    tickets.filter((t) =>
      t.teachers.some((u) => u.accountId === self.id && u.status === 'active')
    )
);

export function useMyHelpingTickets() {
  return useSelector(getMyHelpingTickets);
}

/**
 * Gets only "closed" tickets, optionally filtered for a particular course
 */
export const getRecentTickets = createOrmSelector(
  orm.Course.tickets,
  (tickets) =>
    tickets
      .flat()
      .filter((t) => t.status === 'completed')
      .sort(newestFirst)
);

export function useRecentTickets() {
  return useSelector(getRecentTickets);
}

/**
 * Gets only "closed" tickets from the store for which the authenticated user
 *   was a participant, optionally filtered for a particular course
 */
export const getMyRecentTickets = createSelector(
  getRecentTickets,
  getSelf,
  (tickets, self) =>
    tickets.filter(
      (t) =>
        t.teachers.some((u) => u.accountId === self.id) ||
        t.students.some((u) => u.accountId === self.id)
    )
);

export function useMyRecentTickets() {
  return useSelector(getMyRecentTickets);
}

/**
 * Sorting functions
 */
function oldestFirst(a, b) {
  return new Date(a.requestedAt) - new Date(b.requestedAt);
}

function newestFirst(a, b) {
  return -oldestFirst(a, b);
}
