import React from 'react';

import {
  VirtualHelpButton,
  FollowupButton,
  MarkCompletedButton,
  OpenTicketButton,
  CallStudentButton,
} from '../ticket-action-buttons';

export default function TicketActions({ ticketId, status, onClick }) {
  switch (status) {
    case 'waiting':
      return (
        <>
          <VirtualHelpButton id={ticketId} variant="text" onClick={onClick} />
          <OpenTicketButton id={ticketId} variant="text" onClick={onClick} />
          <CallStudentButton
            id={ticketId}
            variant="contained"
            onClick={onClick}
          />
        </>
      );
    case 'helping':
      return (
        <>
          <FollowupButton id={ticketId} variant="text" onClick={onClick} />
          <MarkCompletedButton
            id={ticketId}
            variant="contained"
            onClick={onClick}
          />
        </>
      );
    case 'calling':
      return (
        <OpenTicketButton id={ticketId} variant="contained" onClick={onClick} />
      );
    default:
      return null;
  }
}
