import React, { useCallback } from 'react';

import Typography from '@material-ui/core/Typography';

import Section from 'components/content/section';
import AsyncButton from 'components/buttons/async-button';
import ConfirmDialog from 'components/dialogs/confirm';
import { useCourse } from 'selectors/course-selectors';
import { archiveCourse, activateCourse } from 'actions/course-actions';

export default function ArchiveCourseSection({ courseId }) {
  const course = useCourse(courseId);

  const handleArchive = useCallback(() => {
    return archiveCourse(courseId);
  }, [courseId]);

  const handleActivate = useCallback(() => {
    return activateCourse(courseId);
  }, [courseId]);

  if (!course) {
    return null; // TODO: add placeholder
  }

  return (
    <Section title="Archive course">
      <Typography paragraph>
        Archiving your course is a <strong>reversible</strong> action that
        disables students and staff from using My Digital Hand.
      </Typography>

      <Typography paragraph>
        We recommend archiving your course after the semester ends to prevent
        confusion and accidental changes to your data.
      </Typography>

      <Typography paragraph>
        Your course is <strong>{course.status}</strong>.
      </Typography>

      {course.status === 'archived' && (
        <AsyncButton
          variant="outlined"
          color="primary"
          onClick={handleActivate}
        >
          Activate course
        </AsyncButton>
      )}

      {course.status === 'active' && (
        <ConfirmDialog
          title="Archive course?"
          content={`Are you sure you want to archive ${course.code}? This will automatically end all active office hours sessions and disable students from using the tool. We recommend only performing this action after the end of the semester.`}
          buttonProps={{ variant: 'contained', color: 'primary' }}
          buttonText="Archive course"
          acceptText="Archive"
          acceptProps={{ variant: 'contained', color: 'primary' }}
          rejectText="Keep active"
          rejectProps={{ variant: 'text', color: 'primary' }}
          onConfirm={handleArchive}
        />
      )}
    </Section>
  );
}
