import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// const options = [
//   {
//     value: 'external',
//     text: 'Link to an external calendar',
//   },
//   {
//     value: 'disabled',
//     text: 'Disable the schedule in the menu',
//   },
// ];

export default function DropdownSelect({ value, onChange, title, options }) {
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleOpenMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleSelectItem = (selectedValue) => () => {
    onChange instanceof Function && onChange(selectedValue);
    setMenuAnchor(null);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const subtitle = options.find((o) => o.value === value)?.text;

  return (
    <>
      <List component="nav">
        <ListItem button onClick={handleOpenMenu}>
          <ListItemText primary={title} secondary={subtitle} />
        </ListItem>
      </List>
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={handleCloseMenu}
      >
        {options.map(({ value: v, text }) => (
          <MenuItem
            key={v}
            selected={value === v}
            onClick={handleSelectItem(v)}
          >
            {text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
