import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { closeTicket } from 'actions/ticket-actions';
import UserList from 'components/user/user-list';
import ConfirmDialog from 'components/dialogs/confirm';

import HelpDialog from './help-dialog';

export default function HandCardHelping({ ticket }) {
  const dispatch = useDispatch();
  const { id } = ticket;
  const teachers = ticket.teachers.filter((t) => t.status === 'active');

  const handleCancelClick = useCallback(() => {
    dispatch(closeTicket(id));
  }, [id, dispatch]);

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography gutterBottom variant="h5">
          Getting help now
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p">
          You&apos;re currently being helped by the following help staff{' '}
          {teachers.length > 1 ? 'members' : 'member'}.
        </Typography>

        <UserList
          userIds={teachers.map((t) => t.userId)}
          courseId={ticket.courseId}
        />
      </CardContent>

      <CardActions>
        <ConfirmDialog
          title="Close this help request?"
          content="Normally when you're finished getting help, the staff member you worked with will close your help request ticket for you. If they forgot, you can use this option to force your help request closed."
          buttonProps={{ color: 'secondary', variant: 'contained' }}
          buttonText="Leave office hours"
          acceptText="Close request"
          acceptProps={{ color: 'secondary', variant: 'contained' }}
          rejectText="I'm still being helped"
          rejectProps={{ color: 'primary' }}
          onConfirm={handleCancelClick}
        />

        <HelpDialog currentStep={4} />
      </CardActions>
    </Card>
  );
}
