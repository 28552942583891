import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import NavBar from '../../../../components/app-bar/app-bar';
import EditableQuestionsList from './editable-questions-list';
import FeedbackHeaderSection from './header-section';
import FeedbackRequiredSection from './feedback-required-section';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    maxWidth: '600px',
  },
}));

export default function FeedbackPage() {
  const classes = useStyles();
  const params = useParams();
  const courseId = Number(params.courseId);
  const [field, setField] = useState('prompts');

  const handleTabChange = (event, value) => {
    setField(value);
  };

  return (
    <>
      <NavBar
        title="Configure feedback"
        back={`/courses/${courseId}/settings`}
      />

      <Paper>
        <Tabs
          value={field}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Pre-interaction prompts" value="prompts" />
          <Tab label="Student feedback" value="studentFeedback" />
          <Tab label="Staff feedback" value="teacherFeedback" />
        </Tabs>
      </Paper>

      <Box className={classes.content}>
        <FeedbackHeaderSection field={field} />
        <EditableQuestionsList courseId={courseId} field={field} />
      </Box>

      {field !== 'prompts' && (
        <FeedbackRequiredSection courseId={courseId} field={field} />
      )}
    </>
  );
}
