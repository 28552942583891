import React from 'react';
import { useParams } from 'react-router-dom';
import { useIsAuthenticated } from 'selectors/auth-selectors';

import AcceptDialog from './accept-dialog';

export default function AcceptPage() {
  const isAuthenticated = useIsAuthenticated();
  const { id } = useParams();

  return <AcceptDialog open id={id} isAuthenticated={isAuthenticated} />;
}
