import React from 'react';

import Box from '@material-ui/core/Box';

import NavDrawer from 'components/drawers/nav-drawer/nav-drawer';
import { useCourses } from 'selectors/course-selectors';
import AppBar from 'components/app-bar/app-bar';

import ActiveCoursesSection from './active-courses-section';
import InactiveCoursesSection from './inactive-courses-section';

export default function HomeRoute() {
  const courses = useCourses();

  return (
    <NavDrawer>
      <AppBar title="Your courses">
        <Box m={2}>
          <ActiveCoursesSection courses={courses} />
          <InactiveCoursesSection courses={courses} />
        </Box>
      </AppBar>
    </NavDrawer>
  );
}
