import React from 'react';
import pluralize from 'pluralize';

import Typography from '@material-ui/core/Typography';

import UserList from 'components/user/user-list';
import { useCourse } from 'selectors/course-selectors';
import { useWaitingTickets } from 'selectors/ticket-selectors';

import ordinal from 'utils/ordinal';

export default function WaitingQueueInfo({ ticketId, courseId }) {
  const course = useCourse(courseId);

  switch (course?.queueStatusPolicy) {
    case 'names':
      return <CompleteQueue courseId={courseId} />;
    case 'position':
      return <PositionInQueue courseId={courseId} ticketId={ticketId} />;
    case 'total':
      return <TotalInQueue courseId={courseId} />;
    case 'none':
    default:
      return <InQueue />;
  }
}

function CompleteQueue({ courseId, ticketId }) {
  const waitlist = useWaitingTickets(courseId);
  const userIds = waitlist
    .map((u) => u.students[0])
    .filter((s) => s)
    .map((s) => s.userId);
  return (
    <>
      <PositionInQueue courseId={courseId} ticketId={ticketId} />
      <UserList userIds={userIds} numbered />
    </>
  );
}

function PositionInQueue({ courseId, ticketId, ...params }) {
  const waitlist = useWaitingTickets(courseId);
  const numOthersInLine = waitlist.length - 1;
  const placeInLine = waitlist.map((t) => t.id).indexOf(ticketId);

  if (numOthersInLine < 1) {
    return (
      <Typography gutterBottom variant="h5" {...params}>
        You are the only one in line
      </Typography>
    );
  } else {
    return (
      <Typography gutterBottom variant="h5" {...params}>
        You are {placeInLine <= 0 ? 'next' : ordinal(placeInLine + 1)} in line (
        {waitlist.length} total)
      </Typography>
    );
  }
}

function TotalInQueue({ courseId, ...params }) {
  const waitlist = useWaitingTickets(courseId);
  const numOthersInLine = waitlist.length - 1;

  if (numOthersInLine < 1) {
    return (
      <Typography gutterBottom variant="h5" {...params}>
        You are the only one in line
      </Typography>
    );
  } else {
    return (
      <Typography gutterBottom variant="h5" {...params}>
        You are in line with {pluralize('other student', numOthersInLine, true)}
      </Typography>
    );
  }
}

function InQueue(params) {
  return (
    <Typography gutterBottom variant="h5" {...params}>
      You are waiting in line
    </Typography>
  );
}
