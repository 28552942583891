import { Model, attr, fk } from 'redux-orm';

export default class User extends Model {
  static reducer(action, User, session) {
    switch (action.type) {
      case 'USER_FETCHED':
      case 'USER_ADDED':
      case 'USER_CHANGED':
        User.upsert(action.payload);
        break;
      case 'PRESENCE_CHANGED':
        User.all()
          .filter((u) => u.accountId === action.payload.id)
          .update(action.payload);
        break;
      case 'USER_REMOVED':
        if (User.idExists(action.payload)) {
          User.withId(action.payload).update({ status: 'disabled' });
        }
        break;
      case 'AUTH_INVALIDATED':
        User.all()
          .toModelArray()
          .forEach((u) => u.delete());
        break;
      case 'SHIFT_STARTED':
        if (User.idExists(action.payload.userId)) {
          User.withId(action.payload.userId).update({ shift: action.payload });
        }
        break;
      case 'SHIFT_CHANGED':
        if (User.idExists(action.payload.userId)) {
          const user = User.withId(action.payload.userId);
          user.update({ shift: { ...user.shift, ...action.payload } });
        }
        break;
      case 'SHIFT_ENDED':
        if (User.idExists(action.payload.userId)) {
          User.withId(action.payload.userId).update({ shift: null });
        }
        break;
      default:
    }
  }
}

User.modelName = 'User';

User.fields = {
  id: attr(),
  accountId: attr(),
  courseId: fk({
    to: 'Course',
    as: 'course',
    relatedName: 'users',
  }),

  role: attr(),
  status: attr(),
  presence: attr(),

  first: attr(),
  last: attr(),
  group: attr(),
  email: attr(),
  avatar: attr(),

  shift: attr(),
  identifier: attr(),
};
