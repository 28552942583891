import React, { memo, useState } from 'react';
import MaterialTable from 'material-table';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { ExportToCsv } from 'export-to-csv';
import pluralize from 'pluralize';

import ResendEmailIcon from '@material-ui/icons/Refresh';
import ChangeRoleIcon from '@material-ui/icons/MoveToInbox';
import DeleteIcon from '@material-ui/icons/Delete';

import { useSelf } from 'selectors/auth-selectors';
import { useCourse } from 'selectors/course-selectors';
import useScreenSize from 'hooks/use-screen-size';
import capitalize from 'utils/capitalize';

import InviteDialog from './invite-dialog/invite-dialog';
import Icons from './roster-table-icons';
import AvatarCell from './avatar-cell';
import NameCell from './name-cell';
import EmailCell from './email-cell';
import RoleMenu from './role-menu';

const theme = (theme) =>
  createMuiTheme({
    ...theme,
    overrides: {
      MuiToolbar: {
        root: {
          '& > div > div': {
            whiteSpace: 'nowrap',
          },
        },
      },
    },
  });

export default memo(RosterTable);

function RosterTable({
  courseId,
  data,
  onDelete,
  onMove,
  onInvite,
  role,
  ...props
}) {
  const screenSize = useScreenSize();
  const course = useCourse(courseId);
  const [anchor, setAnchor] = useState(null);
  const [selection, setSelection] = useState([]);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const self = useSelf();

  if (!course || !self) {
    return null;
  }

  const smDown = ['xs', 'sm'].includes(screenSize);

  const handleOpenInviteDialog = () => {
    setIsInviteDialogOpen(true);
  };

  const handleCloseInviteDialog = () => {
    setIsInviteDialogOpen(false);
  };

  const handleDelete = (event, rows) => {
    if (onDelete instanceof Function) {
      return onDelete(rows);
    }
  };

  const handleExport = () => {
    const csvExporter = new ExportToCsv({
      filename: exportRosterFileName(course),
      useKeysAsHeaders: true,
    });
    csvExporter.generateCsv(
      data.map((row) => ({
        first: row.first ?? '',
        last: row.last ?? '',
        email: row.email ?? '',
        status:
          {
            pending: 'Pending email confirmation',
            active: 'Active',
            invited: 'Invited',
          }[row.status] ?? '',
      }))
    );
  };

  const handleRoleMenuOpen = (event, rows) => {
    setAnchor(event.currentTarget);
    setSelection(rows);
  };

  const handleChangeRole = (newRole) => {
    setAnchor(null);
    if (onMove instanceof Function) {
      return onMove(selection, newRole);
    }
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <MaterialTable
        data={data}
        icons={Icons}
        title={`${capitalize(role)} roster`}
        columns={[
          {
            render: (row) => <AvatarCell {...row} />,
            width: 60,
            cellStyle: { padding: '10px 0' },
          },
          {
            title: 'Name',
            defaultSort: 'asc',
            customSort: LastFirstEmail,
            render: (row) => <NameCell {...row} />,
            cellStyle: { padding: 0 },
            headerStyle: { padding: 0 },
          },
          {
            title: 'Email',
            field: 'email',
            hidden: smDown,
            render: (row) => (
              <EmailCell email={row.email} status={row.status} />
            ),
            searchable: true,
            cellStyle: { padding: 0 },
            headerStyle: { padding: 0 },
          },
          {
            hidden: true,
            field: 'first',
            searchable: true,
          },
          {
            hidden: true,
            field: 'last',
            searchable: true,
          },
          {
            hidden: true,
            field: 'status',
            searchable: true,
          },
        ]}
        options={{
          paging: true,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          rowStyle: (row) => ({
            backgroundColor: row.tableData.checked ? '#ca131311' : '',
          }),
          selection: true,
          selectionProps: (row) => ({
            disabled: row.accountId === self.id,
          }),
          showTitle: !smDown,
          showTextRowsSelected: !smDown,
          tableLayout: 'fixed',
          thirdSortClick: false,
          toolbarButtonAlignment: 'left',
        }}
        onRowClick={(event, row) => {
          const input = event.currentTarget.querySelector('input');
          input && input.click();
        }}
        actions={[
          {
            isFreeAction: true,
            tooltip: 'Export to CSV',
            icon: Icons.Export,
            onClick: handleExport,
          },
          {
            isFreeAction: true,
            tooltip: `Add ${pluralize(role)}`,
            icon: Icons.Add,
            onClick: handleOpenInviteDialog,
          },
          {
            tooltip: 'Resend invitation',
            icon: () => <ResendEmailIcon color="secondary" />,
            onClick: (event, rows) => alert('Resend invitations'),
          },
          {
            tooltip: 'Move to',
            icon: () => <ChangeRoleIcon color="secondary" />,
            onClick: handleRoleMenuOpen,
          },
          {
            tooltip: 'Remove from course',
            icon: () => <DeleteIcon color="secondary" />,
            onClick: handleDelete,
          },
        ]}
        localization={{
          toolbar: {
            nRowsSelected: '{0} selected',
          },
        }}
        {...props}
      />
      <RoleMenu
        exclude={role}
        onSelect={handleChangeRole}
        onClose={handleCloseMenu}
        anchor={anchor}
      />

      <InviteDialog
        open={isInviteDialogOpen}
        onClose={handleCloseInviteDialog}
        onInvite={onInvite}
        role={role}
        courseId={courseId}
      />
    </ThemeProvider>
  );
}

function LastFirstEmail(a, b) {
  const strA = `${a.last ?? ''}${a.first ?? ''}${a.email}`.toLowerCase();
  const strB = `${b.last ?? ''}${b.first ?? ''}${b.email}`.toLowerCase();
  return strA.localeCompare(strB);
}

function exportRosterFileName(course) {
  return [course.code, course.semester, course.year, 'roster']
    .filter((x) => x)
    .join('-')
    .replace(' ', '');
}
