import React from 'react';
import Placeholder from 'react-placeholder';

import Typography from '@material-ui/core/Typography';

import { useTicket } from 'selectors/ticket-selectors';
import QuestionPrompt from './question-prompt';

export default function FollowupRow({ parentId }) {
  const ticket = useTicket(parentId);

  if (!ticket || !ticket.prompts) {
    return <Placeholder rows={1} />;
  }

  return (
    <>
      <Typography paragraph variant="subtitle2">
        Follow-up to a previous question
      </Typography>
      {ticket.prompts.map((p) => (
        <QuestionPrompt key={p.question.prompt} {...p} />
      ))}
    </>
  );
}
