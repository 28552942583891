import request from 'api/request';

export function chatFetching(id) {
  return {
    type: 'CHAT_FETCHING',
    payload: id,
  };
}

export function chatFetched(attrs) {
  return {
    type: 'CHAT_FETCHED',
    payload: attrs,
  };
}

export function chatSent(attrs) {
  return {
    type: 'CHAT_SENT',
    payload: attrs,
  };
}

export function chatDelivered(attrs) {
  return {
    type: 'CHAT_DELIVERED',
    payload: attrs,
  };
}

export function chatRead(attrs) {
  return {
    type: 'CHAT_READ',
    payload: attrs,
  };
}

const promises = {};
export function fetchChatMessage(id) {
  return (dispatch) => {
    if (id in promises) {
      return promises[id];
    }
    dispatch(chatFetching(id));
    const promise = request({ url: `/chat/messages/${id}` })
      .then(({ data }) => {
        dispatch(chatFetched(data));
      })
      .finally(() => {
        delete promises[id];
      });
    promises[id] = promise;
    return promise;
  };
}

export function fetchThread(userId, pageNum = 0, pageSize = 25) {
  return (dispatch) =>
    request({
      url: `/chat/threads/${userId}`,
      data: { limit: pageSize, offset: pageSize * pageNum },
    }).then(({ data }) => {
      data.forEach((chat) => dispatch(chatFetched(chat)));
    });
}

export function sendChatMessage(data) {
  return request({ url: `/chat/messages`, method: 'POST', data });
}
