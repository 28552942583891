import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Section from 'components/content/section';
import { useWaitingTickets } from 'selectors/ticket-selectors';
import TicketCard from './ticket-card/ticket-card';
import DetailsDialog from './details-dialog/details-dialog';

export default function WaitingSection({ courseId }) {
  const [selection, setSelection] = useState(null);
  const tickets = useWaitingTickets(courseId);

  const handleClose = () => {
    setSelection(null);
  };

  if (Array.isArray(tickets) && tickets.length > 0) {
    return (
      <Section title={`Waiting students (${tickets.length})`}>
        {tickets.map((t) => (
          <TicketCard key={t.id} ticket={t} onClick={setSelection} />
        ))}

        {Number.isInteger(selection) && (
          <DetailsDialog ticketId={selection} onClose={handleClose} />
        )}
      </Section>
    );
  } else {
    return (
      <Section title="Waiting students">
        <Typography paragraph>
          Great job! The office hours waitlist is empty.
        </Typography>
      </Section>
    );
  }
}
