import React, { useState, useCallback, useEffect } from 'react';

import AcceptButtonContent from './accept-button-content';
import EmailSentContent from './email-sent-content';
import ErrorContent from './error-content';
import LoadingContent from './loading-content';
import SigninContent from './signin-content';
import SignupContent from './signup-content';
import SuccessContent from './success-content';

import { fetchInvitation } from 'actions/invitation-actions';
import Dialog from 'components/content/dialog';

export default function AcceptDialog({ isAuthenticated, id, ...params }) {
  const [state, setState] = useState('loading');
  const [invitation, setInvitation] = useState(null);

  useEffect(() => {
    if (isAuthenticated && ['signin', 'signup'].includes(state)) {
      setState('accept-button');
    }
  }, [state, isAuthenticated]);

  useEffect(() => {
    let isActive = true;
    fetchInvitation(id).then(({ status, data }) => {
      if (isActive) {
        switch (status) {
          case 200:
            setInvitation(data);
            setState('signup');
            break;
          default:
            setState('error');
        }
      }
    });
    return () => {
      isActive = false;
    };
  }, [id]);

  const handleTransition = useCallback((newState) => {
    setState(newState);
  }, []);

  switch (state) {
    case 'loading':
      return (
        <Dialog fullWidth {...params}>
          <LoadingContent />
        </Dialog>
      );
    case 'error':
      return (
        <Dialog fullWidth {...params}>
          <ErrorContent />
        </Dialog>
      );
    case 'email-sent':
      return (
        <Dialog fullWidth {...params}>
          <EmailSentContent email={invitation.email.address} />
        </Dialog>
      );
    case 'signup':
      return (
        <Dialog fullWidth {...params}>
          <SignupContent
            courseName={invitation.course.code}
            role={invitation.role}
            email={invitation.email.address}
            onTransition={handleTransition}
          />
        </Dialog>
      );
    case 'signin':
      return (
        <Dialog fullWidth {...params}>
          <SigninContent
            courseName={invitation.course.code}
            role={invitation.role}
            email={invitation.email.address}
            onTransition={handleTransition}
          />
        </Dialog>
      );
    case 'accept-button':
      return (
        <Dialog fullWidth {...params}>
          <AcceptButtonContent
            invitationId={id}
            courseName={invitation.course.code}
            role={invitation.role}
            onTransition={handleTransition}
          />
        </Dialog>
      );
    case 'success':
      return (
        <Dialog fullWidth {...params}>
          <SuccessContent
            courseName={invitation.course.code}
            role={invitation.role}
            courseId={invitation.course.id}
          />
        </Dialog>
      );
    default:
      return null;
  }
}
