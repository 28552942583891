import React from 'react';

import Typography from '@material-ui/core/Typography';

export default function FeedbackHeaderSection({ field }) {
  if (field === 'prompts') {
    return (
      <>
        <Typography variant="h6" paragraph>
          Pre-interaction prompts
        </Typography>

        <Typography paragraph>
          Students will be asked these questions before they are allowed to add
          themselves to the office hours waitlist. Responses will be shared with
          staff.
        </Typography>
      </>
    );
  } else if (field === 'studentFeedback') {
    return (
      <>
        <Typography variant="h6" paragraph>
          Student feedback
        </Typography>

        <Typography paragraph>
          Students will be asked these questions after they finish receiving
          help at office hours. Responses will be kept hidden from staff
          and other students.
        </Typography>
      </>
    );
  } else if (field === 'teacherFeedback') {
    return (
      <>
        <Typography variant="h6" paragraph>
          Staff feedback
        </Typography>

        <Typography paragraph>
          Staff members will be asked these questions after they finish helping
          a student at office hours. Responses will be kept hidden from other
          staff and students.
        </Typography>
      </>
    );
  } else {
    return null;
  }
}
