import React from 'react';
import Moment from 'react-moment';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

import UserAvatar from 'components/user/user-avatar';
import UserNames from 'components/user/user-names';
import { useAccount } from 'selectors/auth-selectors';
import CardPlaceholder from './ticket-card-placeholder';
import FollowUpListItem from './follow-up-list-item';
import RecommendedListItem from './recommended-list-item';

export default function WaitingTicketCard({ ticket, onClick }) {
  const account = useAccount();

  if (!account || !ticket) {
    return <CardPlaceholder />;
  }

  const studentIds = ticket.students.map((s) => s.userId);
  const isStudent = ticket.students.some((s) => s.accountId === account.id);

  const handleClick = () => {
    onClick instanceof Function && onClick(ticket.id);
  };

  return (
    <Box clone mb={2}>
      <Card elevation={3}>
        <CardActionArea disabled={isStudent} onClick={handleClick}>
          <CardHeader
            titleTypographyProps={{ variant: 'body1' }}
            avatar={<UserAvatar ids={studentIds} />}
            title={<UserNames ids={studentIds} />}
            subheader="Waiting for help"
            action={
              <Typography variant="body2" color="textSecondary">
                <Moment date={ticket.requestedAt} fromNow />
              </Typography>
            }
          />

          <List disablePadding dense>
            {ticket.isFollowup && (
              <FollowUpListItem parentId={ticket.parentId} />
            )}
            <RecommendedListItem ticket={ticket} />
          </List>
        </CardActionArea>
      </Card>
    </Box>
  );
}
