/**
 * This file is included at the root of the application by index.jsx. It
 *   registers important event handlers to the authentication and socket
 *   libraries, and fires the corresponding redux actions when these events
 *   occur. This file also kicks off the initial authentication check which
 *   takes place on page load.
 */

import { socket } from './drivers/socket';
import auth from './drivers/auth';
import store from 'store';

import { getAccount } from 'selectors/auth-selectors';
import { getCourses } from 'selectors/course-selectors';

import syncSubscriptions, {
  subscribeToSocketEvents,
} from 'actions/subscription-actions';

import { chatSent, chatDelivered, chatRead } from 'actions/chat-actions';
import {
  courseChanged,
  courseCreated,
  courseDestroyed,
  fetchCourse,
} from 'actions/course-actions';
import {
  socketConnected,
  socketDisconnected,
  socketConnecting,
} from 'actions/socket-actions';
import {
  ticketCreated,
  ticketWaiting,
  ticketCalling,
  ticketHelping,
  ticketCompleted,
  ticketCanceled,
  ticketJoined,
  ticketUnjoined,
  feedbackSubmitted,
} from 'actions/ticket-actions';
import {
  userAdded,
  userChanged,
  userRemoved,
  shiftStarted,
  shiftChanged,
  shiftEnded,
  presenceChanged,
} from 'actions/user-actions';
import {
  authChanged,
  accountChanged,
  authValidated,
  authInvalidated,
} from 'actions/auth-actions';

// The system authenticator was successfully validated via http
auth.on('signin', ({ account }) => {
  store.dispatch(authValidated(account));
  store.dispatch(syncSubscriptions());
  if (!socket.isConnected() && !socket.isConnecting()) {
    socket.reconnect();
    store.dispatch(socketConnecting());
  }
});

// The system authenticator was successfully invalidated via http
auth.on('signout', () => {
  store.dispatch(authInvalidated());
  if (socket.isConnected()) {
    socket.disconnect();
  }
});

// The websocket was connected
socket.on('connect', () => {
  store.dispatch(socketConnected());
  auth.check();
});

// The websocket was disconnected
socket.on('disconnect', () => {
  store.dispatch(socketDisconnected());
});

// Your authentication status changed
socket.on('auth_changed', ({ payload: { isAuthenticated, account } }) => {
  store.dispatch(authChanged(isAuthenticated, account));
});

// Your account data was changed
socket.on('account_changed', ({ payload }) => {
  store.dispatch(accountChanged(payload));
});

// A chat message was sent
socket.on('chat_sent', ({ payload }) => {
  store.dispatch(chatSent(payload));
});

// A chat message was delivered to its recipient
socket.on('chat_delivered', ({ payload }) => {
  store.dispatch(chatDelivered(payload));
});

// A chat message was read by its recipient
socket.on('chat_read', ({ payload }) => {
  store.dispatch(chatRead(payload));
});

// You created a new course
socket.on('course_created', ({ payload: course }) => {
  store.dispatch(courseCreated(course));
  store.dispatch(subscribeToSocketEvents());
});

// One or more properties of a course were changed
socket.on('course_changed', ({ payload }) => {
  store.dispatch(courseChanged(payload));
});

// A course was destroyed
socket.on('course_destroyed', ({ payload: { id } }) => {
  store.dispatch(courseDestroyed(id));
});

// A new ticket was created
socket.on('ticket_created', ({ payload }) => {
  store.dispatch(ticketCreated(payload));
});

// A user was added to an existing ticket
socket.on('ticket_joined', ({ payload }) => {
  store.dispatch(ticketJoined(payload));
});

// A user was removed from an existing ticket
socket.on('ticket_unjoined', ({ payload }) => {
  store.dispatch(ticketUnjoined(payload));
});

// An existing ticket was marked waiting
socket.on('ticket_waiting', ({ payload }) => {
  store.dispatch(ticketWaiting(payload));
});

// An existing ticket was marked calling
socket.on('ticket_calling', ({ payload }) => {
  store.dispatch(ticketCalling(payload));
});

// An existing ticket was marked helping
socket.on('ticket_helping', ({ payload }) => {
  store.dispatch(ticketHelping(payload));
});

// An existing ticket was marked completed
socket.on('ticket_completed', ({ payload }) => {
  store.dispatch(ticketCompleted(payload));
});

// An existing ticket was marked canceled
socket.on('ticket_canceled', ({ payload }) => {
  store.dispatch(ticketCanceled(payload));
});

// You left feedback for one of your finished tickets
socket.on('feedback_submitted', ({ payload: { ticketId, userId } }) => {
  store.dispatch(feedbackSubmitted({ ticketId, userId }));
});

// A user was enrolled in a course
// Note: if the auth user is the new user, then load the course
socket.on('user_added', ({ payload }) => {
  const { courseId, accountId } = payload;
  const { dispatch } = store;
  const state = store.getState();
  dispatch(userAdded(payload));
  const account = getAccount(state);
  const course = getCourses(state, courseId);
  if (account && !course && account.id === accountId) {
    fetchCourse(courseId)(dispatch);
  }
});

// A user's data changed
socket.on('user_changed', ({ payload }) => {
  store.dispatch(userChanged(payload));
});

// A user was removed from a course
socket.on('user_removed', ({ payload: { id } }) => {
  store.dispatch(userRemoved(id));
});

// A user's presence changed
socket.on('presence_changed', ({ payload }) => {
  store.dispatch(presenceChanged(payload));
});

// A user's shift started
socket.on('shift_started', ({ payload }) => {
  store.dispatch(shiftStarted(payload));
});

// A user's shift was changed
socket.on('shift_changed', ({ payload }) => {
  store.dispatch(shiftChanged(payload));
});

// A user's shift ended
socket.on('shift_ended', ({ payload }) => {
  store.dispatch(shiftEnded(payload));
});

// Try to connect the socket on page load
socket.reconnect();
