import { useEffect } from 'react';
import { createSelector } from 'redux-orm';
import orm from 'models/orm';
import { useSelector, useDispatch } from 'react-redux';

import { fetchCourse, fetchEntryCodes } from 'actions/course-actions';

export const getCourses = createSelector(orm.Course);

export function useCourse(id) {
  const dispatch = useDispatch();
  const course = useSelector((store) => getCourses(store, id));
  if (Number.isInteger(id) && !course) {
    dispatch(fetchCourse(id));
  }
  return course;
}

export function useCourses() {
  return useSelector(getCourses);
}

export function useCourseEntryCodes(id) {
  const dispatch = useDispatch();
  const course = useCourse(id);

  useEffect(() => {
    dispatch(fetchEntryCodes(id));
  }, [dispatch, id]);

  if (course) {
    return {
      canStudentsEnrollByCode: course.canStudentsEnrollByCode,
      studentEnrollCode: course.studentEnrollCode,
      canStaffEnrollByCode: course.canStaffEnrollByCode,
      staffEnrollCode: course.staffEnrollCode,
    };
  } else {
    return null;
  }
}
