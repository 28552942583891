import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function EmailCell({ email, status }) {
  return (
    <>
      <Typography variant="body1">{email}</Typography>
      {status === 'pending' && (
        <Typography variant="body2" color="error">
          (unconfirmed)
        </Typography>
      )}
      {status === 'invited' && (
        <Typography variant="body2" color="primary">
          (invitation sent)
        </Typography>
      )}
    </>
  );
}
