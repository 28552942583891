import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import ChatIcon from '@material-ui/icons/Chat';

import { useStaff } from 'selectors/staff-selector';
import UserAvatar from 'components/user/user-avatar';

export default function StaffSection({ courseId }) {
  const { working, online } = useStaffAvailability(courseId);
  const users = [...working, ...online];

  return (
    <Box pt={2}>
      <Paper>
        <Box px={2} pt={2}>
          <Typography variant="h6" paragraph>
            Available staff
          </Typography>

          <List>
            {users.map((user) => (
              <StaffListItem
                key={user.id}
                courseId={courseId}
                id={user.id}
                first={user.first}
                last={user.last}
                endAt={user.shift?.endAt}
                presence={user.presence}
              />
            ))}
          </List>
        </Box>
      </Paper>
    </Box>
  );
}

function StaffListItem({ courseId, id, first, last, endAt, presence }) {
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <UserAvatar id={id} />
      </ListItemAvatar>
      <ListItemText
        primary={`${first} ${last}`}
        secondary={<StaffAvailability endAt={endAt} presence={presence} />}
      />
      <ListItemSecondaryAction>
        <Tooltip title={`Message ${first}`}>
          <IconButton
            color="default"
            size="small"
            edge="end"
            component={Link}
            to={`/courses/${courseId}/chat/${id}`}
          >
            <ChatIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function StaffAvailability({ endAt, presence }) {
  if (new Date(endAt).getTime() > Date.now()) {
    return (
      <>
        Available until <Moment format="h:mm a">{endAt}</Moment> (
        <Moment fromNow>{endAt}</Moment>)
      </>
    );
  } else if (presence === 'online') {
    return 'Online';
  } else {
    return <em>Offline</em>;
  }
}

function useStaffAvailability(courseId) {
  const staff = useStaff(courseId);
  const working = [];
  const online = [];
  const offline = [];
  staff.forEach((user) => {
    if (user.shift && new Date(user.shift.endAt).getTime() > Date.now()) {
      working.push(user);
    } else if (user.presence === 'online') {
      online.push(user);
    } else {
      offline.push(user);
    }
  });
  return { working, online, offline };
}
