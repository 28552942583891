import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import {
  regenerateCourseCode,
  fetchEntryCodes,
} from '../../../../actions/course-actions';

import ConfirmDialog from '../../../../components/dialogs/confirm';
import Section from '../../../../components/content/section';

export default function ResetCodesSection({ role, courseId }) {
  const dispatch = useDispatch();

  const fieldName = `${role}EnrollCode`;

  const handleConfirm = useCallback(async () => {
    await regenerateCourseCode({ id: courseId, field: fieldName });
    await dispatch(fetchEntryCodes(courseId));
  }, [dispatch, courseId, fieldName]);

  return (
    <Section title={`Reset ${role} enrollment code`} m={2}>
      <Typography paragraph>
        If you are worried that your QR or entry codes were compromised, you can
        reset them here.
      </Typography>

      <Typography paragraph>
        Resetting will generate new {role} QR and entry codes for your course,{' '}
        <strong>and will permanently disable your old ones.</strong>
      </Typography>

      <Typography paragraph>
        <ConfirmDialog
          title={`Reset ${role} entry code`}
          buttonProps={{ variant: 'contained' }}
          buttonText="Reset code"
          acceptText="Reset code"
          acceptProps={{ variant: 'contained', color: 'secondary' }}
          rejectText="Keep current code"
          onConfirm={handleConfirm}
        >
          <Typography paragraph>
            Are you sure you want to generate new {role} QR and entry codes for
            this course?
          </Typography>

          <Typography paragraph>
            <strong>
              This will permanently invalidate your current {role} QR and entry
              codes.
            </strong>
          </Typography>
        </ConfirmDialog>
      </Typography>
    </Section>
  );
}
