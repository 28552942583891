import React from 'react';

import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';

import ExpandIcon from '@material-ui/icons/ExpandMore';

import CourseCard from './course-card';
import CourseSearchButton from './course-search/course-search-button';
import CourseCreateButton from './course-create/course-create-button';

export default function ActiveCoursesSection({ courses }) {
  courses = courses.filter(
    (course) => course.status === 'active' && course.role
  );

  return (
    <Box clone mb={2}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandIcon />}>
          <Typography variant="h5">
            Active courses ({courses.length})
          </Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Box>
            {courses.map((course) => (
              <CourseCard course={course} key={course.id} />
            ))}
          </Box>
        </ExpansionPanelDetails>

        <ExpansionPanelActions>
          <CourseSearchButton color="primary" />
          <CourseCreateButton color="primary" variant="contained" />
        </ExpansionPanelActions>
      </ExpansionPanel>
    </Box>
  );
}
