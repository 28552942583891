import React, { useState, useCallback } from 'react';
import pluralize from 'pluralize';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/Check';

import useScreenSize from 'hooks/use-screen-size';

import RosterCsvImport from './roster-csv-import';
import RosterTableUpload from './roster-table-upload';

export default function InviteDialog({
  open,
  onClose,
  onInvite,
  role,
  courseId,
}) {
  const [csvRows, setCsvRows] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const screenSize = useScreenSize();
  const isMobile = screenSize === 'xs';

  const handleBackToCsvImport = () => {
    setCsvRows(null);
  };

  const handleClose = () => {
    onClose instanceof Function && onClose();
    setAddresses(null);
    setCsvRows(null);
  };

  const handleUpload = useCallback(
    (newAddresses) => {
      onInvite instanceof Function && onInvite(newAddresses);
      setAddresses(newAddresses);
    },
    [onInvite]
  );

  if (addresses) {
    return (
      <Dialog open={open} fullScreen={isMobile} maxWidth="sm" fullWidth>
        <DialogTitle>Success</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {pluralize(
              role === 'staff' ? 'staff member' : role,
              addresses.length,
              true
            )}{' '}
            {addresses.length === 1 ? 'was' : 'were'} invited by email to join
            your course.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            startIcon={<SuccessIcon />}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else if (csvRows) {
    return (
      <Dialog open={open} fullScreen={isMobile} maxWidth="md">
        <DialogTitle disableTypography>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h6">
                Invite {pluralize(role)} by email
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <RosterTableUpload
          courseId={courseId}
          role={role}
          data={csvRows}
          onBack={handleBackToCsvImport}
          onUpload={handleUpload}
        />
      </Dialog>
    );
  } else {
    return (
      <Dialog open={open} fullScreen={isMobile} maxWidth="sm" fullWidth>
        <DialogTitle disableTypography>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h6">
                Invite {pluralize(role)} by email
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <RosterCsvImport role={role} onImport={setCsvRows} />
      </Dialog>
    );
  }
}
