import request from 'api/request';

export function startShift(data) {
  return request({ url: '/shifts', method: 'POST', data });
}

export function updateShift({ id, ...data }) {
  return request({ url: `/shifts/${id}`, method: 'PUT', data });
}

export function endShift(id) {
  return request({ url: `/shifts/${id}`, method: 'DELETE' });
}
