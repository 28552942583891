import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import AppBar from 'components/app-bar/app-bar';

import HandCard from './hand-card/hand-card';
import StaffSection from './staff-section';
import OfficeHoursMenu from '../menu/office-hours-menu';

const useStyles = makeStyles({
  wrapper: {
    maxWidth: '700px',
  },
});

export default function HandPage() {
  const classes = useStyles();
  const params = useParams();
  const courseId = Number(params.courseId);

  return (
    <>
      <AppBar
        title="Get help at office hours"
        menu={<OfficeHoursMenu courseId={courseId} />}
      />

      <Box m={2} className={classes.wrapper}>
        <HandCard courseId={courseId} />

        <StaffSection courseId={courseId} />
      </Box>
    </>
  );
}
