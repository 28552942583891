import React, { useCallback } from 'react';

import Typography from '@material-ui/core/Typography';

import Section from 'components/content/section';
import ConfirmDialog from 'components/dialogs/confirm';
import { useCourse } from 'selectors/course-selectors';
import { destroyCourse } from 'actions/course-actions';

export default function CourseDisableSection({ courseId }) {
  const course = useCourse(courseId);

  const handleDestroy = useCallback(() => {
    return destroyCourse(courseId);
  }, [courseId]);

  return (
    <Section title="Destroy course">
      <Typography paragraph>
        Destroying your course is an <strong>irreversible</strong> action that
        permanently deletes all course-related data from the My Digital Hand
        server.
      </Typography>

      <Typography paragraph>
        Consider archiving your course instead. If you&apos;re sure, maybe you
        want to download a copy of all course data before you destroy it.
      </Typography>

      <ConfirmDialog
        title="Permanently destroy course?"
        content={`Are you sure you want to permanently destroy all data associated with ${course.code}? This action is irreversible!`}
        buttonProps={{ variant: 'contained', color: 'secondary' }}
        buttonText="Destroy course"
        acceptText="Destroy"
        acceptProps={{ variant: 'contained', color: 'secondary' }}
        rejectText="Keep active"
        rejectProps={{ variant: 'text', color: 'primary' }}
        onConfirm={handleDestroy}
      />
    </Section>
  );
}
