import React, { useState, useEffect } from 'react';
import { useCourse } from 'selectors/course-selectors';
import QueuePolicySelect from './queue-policy-select';
import { updateCourse } from 'actions/course-actions';

export default function QueuePolicyForm({ courseId }) {
  const course = useCourse(courseId);
  const queueStatusPolicy = course?.queueStatusPolicy;
  const [value, setValue] = useState(queueStatusPolicy ?? 'none');

  useEffect(() => {
    if (queueStatusPolicy) {
      setValue(queueStatusPolicy);
    }
  }, [queueStatusPolicy]);

  const handleChange = (newValue) => {
    setValue(newValue);
    updateCourse({ id: courseId, queueStatusPolicy: newValue });
  };

  return <QueuePolicySelect value={value} onChange={handleChange} />;
}
