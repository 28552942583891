import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import AppBar from 'components/app-bar/app-bar';
import BasicAttributesSection from './basic-attributes-section';
import ArchiveCourseSection from './archive-course-section';
import DestroyCourseSection from './destroy-course-section';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    maxWidth: '600px',
  },
}));

export default function AttributesPage() {
  const classes = useStyles();
  const params = useParams();
  const courseId = Number(params.courseId);

  return (
    <>
      <AppBar
        title="Course attributes"
        back={`/courses/${courseId}/settings`}
      />

      <Box p={1} clone>
        <Paper>
          <Box className={classes.content}>
            <BasicAttributesSection courseId={courseId} />
            <ArchiveCourseSection courseId={courseId} />
            <DestroyCourseSection courseId={courseId} />
          </Box>
        </Paper>
      </Box>
    </>
  );
}
