import React from 'react';
import { Route } from 'react-router-dom';
import ScrollToTop from './scroll-to-top';

import Home from 'pages/home/home';
import SignupPage from 'pages/signup/signup';
import SigninPage from 'pages/signin/signin';
import CoursesRouter from 'pages/courses/courses-router';
import EmailsRouter from 'pages/emails/emails-router';
import PasswordsRouter from 'pages/passwords/passwords-router';
import EnrollPage from 'pages/enroll/enroll-page';
import InvitationsRouter from 'pages/invitations/invitations-router';

import { useIsAuthenticated } from 'selectors/auth-selectors';
import Switch from './nested-switch';

export default function AuthenticationRouter() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <ScrollToTop />

      <Switch>
        {isAuthenticated && (
          <>
            <Route path="/courses/:courseId" component={CoursesRouter} />
            <Route exact path="/" component={Home} />
          </>
        )}

        {!isAuthenticated && (
          <>
            <Route path="/emails" component={EmailsRouter} />
            <Route path="/passwords" component={PasswordsRouter} />
          </>
        )}

        <Route exact path="/signin" component={SigninPage} />
        <Route exact path="/signup" component={SignupPage} />
        <Route path="/enroll/:code" component={EnrollPage} />
        <Route path="/invitations" component={InvitationsRouter} />

        <Route component={SigninPage} />
      </Switch>
    </>
  );
}
