import React, { useState, useEffect } from 'react';
import pluralize from 'pluralize';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import { updateCourse } from '../../../../actions/course-actions';
import Section from '../../../../components/content/section';
import capitalize from '../../../../utils/capitalize';

export default function DisableCodesSection({ courseId, role, codes }) {
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const fieldName = `can${capitalize(pluralize(role))}EnrollByCode`;
  const roleName = role === 'staff' ? 'staff' : role;
  const canEnrollByCode = codes[fieldName];

  useEffect(() => {
    setIsSwitchOn(canEnrollByCode);
  }, [canEnrollByCode]);

  const handleToggle = () => {
    setIsSwitchOn((isSwitchOn) => !isSwitchOn);
    updateCourse({ id: courseId, [fieldName]: !isSwitchOn });
  };

  if (canEnrollByCode === undefined) {
    return null;
  }

  return (
    <Section title={`Disable ${roleName} enrollment by code`} m={2}>
      <Typography paragraph>
        If you do not wish to use code-based enrollment to enroll your students,
        you can disable the setting here.
      </Typography>

      <Typography paragraph>
        Disabling the codes is a reversible action that will disallow{' '}
        {pluralize(roleName)} from using a QR or entry code to gain access to
        this course.
      </Typography>

      <Typography paragraph>
        <FormControlLabel
          label={
            <>
              {capitalize(roleName)} enrollment by QR/entry code is{' '}
              <strong>{isSwitchOn ? 'enabled' : 'disabled'}</strong>
            </>
          }
          control={
            <Switch
              checked={isSwitchOn}
              onChange={handleToggle}
              color="primary"
            />
          }
        />
      </Typography>
    </Section>
  );
}
