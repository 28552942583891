import React, { useState, useMemo, useCallback } from 'react';
import useSWR from 'hooks/use-swr';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import RosterTable from './roster-table';
import NavBar from 'components/app-bar/app-bar';
import capitalize from 'utils/capitalize';

import { removeUsers, updateUserRole } from 'actions/course-actions';

export default function RosterPage() {
  const params = useParams();
  const courseId = Number(params.courseId);
  const [tab, setTab] = useState('student');

  const { data, revalidate, isValidating } = useSWR({
    url: `/courses/${courseId}/users`,
    initialData: [],
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const filtered = useMemo(() => data.filter((row) => row.role === tab), [
    data,
    tab,
  ]);

  const handleInvite = useCallback(() => {
    revalidate();
  }, [revalidate]);

  const handleDelete = useCallback(
    async (rows) => {
      await removeUsers({
        courseId,
        userIds: rows.map((r) => r.id),
      });
      revalidate();
    },
    [courseId, revalidate]
  );

  const handleMove = useCallback(
    async (rows, role) => {
      await updateUserRole({
        courseId,
        userIds: rows.map((r) => r.id),
        role,
      });
      revalidate();
    },
    [courseId, revalidate]
  );

  const handleTabChange = (event, value) => {
    setTab(value);
  };

  return (
    <>
      <NavBar
        title={`${capitalize(tab)} roster`}
        back={`/courses/${courseId}/settings`}
      />

      <Paper>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Students" value="student" />
          <Tab label="Staff" value="staff" />
          <Tab label="Instructors" value="instructor" />
        </Tabs>
      </Paper>

      <Box m={2}>
        <RosterTable
          courseId={courseId}
          data={filtered}
          isLoading={isValidating}
          role={tab}
          onDelete={handleDelete}
          onMove={handleMove}
          onInvite={handleInvite}
        />
      </Box>
    </>
  );
}
