import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Section from 'components/content/section';
import ShiftDialog from './shift-dialog';
import ShiftDetails from './shift-details';
import ShiftEndButton from './shift-end-button';
import { useAccountUser } from 'selectors/user-selectors';
import { useCourse } from 'selectors/course-selectors';

export default function ShiftSection({ courseId }) {
  const course = useCourse(courseId);
  const accountUser = useAccountUser(courseId);
  const shift = accountUser?.shift;
  const isShiftEnabled = course?.shiftPolicy !== 'disabled';

  if (!isShiftEnabled) {
    return null;
  }

  return (
    <Section title="Shift">
      <Box clone p={2}>
        <Paper elevation={3}>
          <ShiftDetails shift={shift} />

          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {isActive(shift) && <ShiftEndButton shiftId={shift.id} />}
            </Grid>
            <Grid item>
              <ShiftDialog shift={shift} courseId={courseId} />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Section>
  );
}

function isActive(shift) {
  return new Date(shift?.endAt).getTime() > Date.now();
}
