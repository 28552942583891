import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';
import CalendarIcon from '@material-ui/icons/DateRange';
import StatisticsIcon from '@material-ui/icons/Equalizer';
import ChatIcon from '@material-ui/icons/Message';
import SettingsIcon from '@material-ui/icons/Tune';
import WaitlistIcon from '@material-ui/icons/List';
import GetHelpIcon from '@material-ui/icons/PanTool';
import HistoryIcon from '@material-ui/icons/History';
import ActivityIcon from '@material-ui/icons/Today';
// import DiscussionIcon from '@material-ui/icons/QuestionAnswerOutlined';
// import LectureIcon from '@material-ui/icons/LibraryBooks';
// import OfficeHoursIcon from '@material-ui/icons/PeopleAlt';

import { useCourse } from 'selectors/course-selectors';

export default function CourseNavList({ courseId }) {
  const course = useCourse(courseId);

  if (!course) {
    return <List />;
  }

  const isInstructor = course.role === 'instructor';
  const isStaff = course.role === 'staff';

  return (
    <List component="div" disablePadding>
      <DrawerListItemLink
        url={`/courses/${courseId}`}
        icon={HomeIcon}
        text="Home"
      />

      {/* <DrawerListItemLink
        url={`/courses/${courseId}/office-hours`}
        icon={OfficeHoursIcon}
        text="Office hours"
      /> */}

      <DrawerListItemLink
        url={`/courses/${courseId}/office-hours/waitlist`}
        icon={WaitlistIcon}
        text="Waitlist"
        isVisible={isInstructor || isStaff}
      />

      <DrawerListItemLink
        url={`/courses/${courseId}/office-hours/hand`}
        icon={GetHelpIcon}
        text="Get help"
      />

      {/* <DrawerListItemLink
        url={`/courses/${courseId}/lectures`}
        icon={LectureIcon}
        text="Lectures"
      /> */}

      <Collapse
        in={course.scheduleConfig === 'external' && Boolean(course.scheduleUrl)}
      >
        <DrawerListItemLink
          href={course.scheduleUrl}
          target="_blank"
          rel="noopener noreferrer"
          icon={CalendarIcon}
          text="Schedule"
          component="a"
        />
      </Collapse>

      {/* <DrawerListItemLink
        url={`/courses/${courseId}/discussion`}
        icon={DiscussionIcon}
        text="Discussion"
      /> */}

      <DrawerListItemLink
        url={`/courses/${courseId}/chat`}
        icon={ChatIcon}
        text="Chat"
      />

      <DrawerListItemLink
        url={`/courses/${courseId}/office-hours/history`}
        icon={HistoryIcon}
        text="History"
      />

      <DrawerListItemLink
        url={`/courses/${courseId}/office-hours/activity`}
        icon={ActivityIcon}
        text="Activity"
        isVisible={isInstructor || isStaff}
      />

      <DrawerListItemLink
        url={`/courses/${courseId}/statistics`}
        icon={StatisticsIcon}
        text="Statistics"
        isVisible={isInstructor}
      />

      <DrawerListItemLink
        url={`/courses/${courseId}/settings`}
        icon={SettingsIcon}
        text="Settings"
        isVisible={isInstructor}
      />
    </List>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
  },
}));

function DrawerListItemLink({
  url,
  icon: Icon,
  text,
  isVisible = true,
  ...params
}) {
  const classes = useStyles();

  if (isVisible) {
    return (
      <ListItem button component={Link} to={url} {...params}>
        <ListItemIcon>
          <Icon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  } else {
    return null;
  }
}
