import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import FollowUpIcon from '@material-ui/icons/KeyboardReturn';
import { useTicket } from 'selectors/ticket-selectors';
import UserNames from 'components/user/user-names';

export default function FollowUpListItem({ parentId }) {
  const ticket = useTicket(parentId);

  return (
    <ListItem>
      <ListItemIcon>
        <FollowUpIcon color="primary" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="body2">Follow-up request</Typography>
        {ticket && (
          <Typography variant="body2" color="textSecondary">
            Last helped by{' '}
            <UserNames ids={ticket.teachers.map((t) => t.userId)} />
          </Typography>
        )}
      </ListItemText>
    </ListItem>
  );
}
