import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

import QuestionTypeIcon from './question-type-icon';
import capitalize from '../../../../../utils/capitalize';

export default function ViewQuestionCard({ question, questionIndex, onClick }) {
  const { type, prompt } = question;

  return (
    <Box clone my={2}>
      <Card>
        <CardActionArea onClick={onClick}>
          <CardHeader
            avatar={
              <Avatar>
                <QuestionTypeIcon type={type} isFilled />
              </Avatar>
            }
            title={
              <Typography variant="body1">
                {questionIndex + 1}. {prompt}
              </Typography>
            }
            subheader={
              <Typography variant="body2" color="textSecondary">
                {capitalize(type.split('-').join(' '), false)} question
              </Typography>
            }
            disableTypography
          />
        </CardActionArea>
      </Card>
    </Box>
  );
}
