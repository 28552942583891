import React, { Fragment } from 'react';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListSubheader from '@material-ui/core/ListSubheader';

import ThreadListItem from './thread-list-item';
import { useChatThreads } from 'selectors/chat-selectors';

export default function ThreadList({ userId }) {
  const threads = useChatThreads(userId);

  if (!Array.isArray(threads) || threads.length === 0) {
    return <NoThreadsFound />;
  }

  return (
    <List
      subheader={<ListSubheader>Conversations</ListSubheader>}
      component="div"
    >
      {threads.map((message, i) => (
        <Fragment key={message.id}>
          <ThreadListItem
            userId={
              message.senderId === userId
                ? message.recipientId
                : message.senderId
            }
            body={message.body}
            updatedAt={message.updatedAt}
          />
          {i + 1 < threads.length && <Divider variant="inset" component="li" />}
        </Fragment>
      ))}
    </List>
  );
}

function NoThreadsFound() {
  return (
    <List subheader={<ListSubheader>Conversations</ListSubheader>}>
      <ListItem>
        <Typography color="textSecondary">No conversations found</Typography>
      </ListItem>
    </List>
  );
}
