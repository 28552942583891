import request from 'api/request';

export function ticketFetching(id) {
  return {
    type: 'TICKET_FETCHING',
    payload: id,
  };
}

export function ticketFetched(attrs) {
  return {
    type: 'TICKET_FETCHED',
    payload: attrs,
  };
}

export function ticketCreated(attrs) {
  return {
    type: 'TICKET_CREATED',
    payload: attrs,
  };
}

export function ticketWaiting(attrs) {
  return {
    type: 'TICKET_WAITING',
    payload: attrs,
  };
}

export function ticketCalling(attrs) {
  return {
    type: 'TICKET_CALLING',
    payload: attrs,
  };
}

export function ticketHelping(attrs) {
  return {
    type: 'TICKET_HELPING',
    payload: attrs,
  };
}

export function ticketCompleted(attrs) {
  return {
    type: 'TICKET_COMPLETED',
    payload: attrs,
  };
}

export function ticketCanceled(attrs) {
  return {
    type: 'TICKET_CANCELED',
    payload: attrs,
  };
}

export function ticketJoined(attrs) {
  return {
    type: 'TICKET_JOINED',
    payload: attrs,
  };
}

export function ticketUnjoined(attrs) {
  return {
    type: 'TICKET_UNJOINED',
    payload: attrs,
  };
}

export function feedbackSubmitted(attrs) {
  return {
    type: 'FEEDBACK_SUBMITTED',
    payload: attrs,
  };
}

const promises = {};
export function fetchTicket(id) {
  return (dispatch) => {
    if (id in promises) {
      return promises[id];
    }
    dispatch(ticketFetching(id));
    const promise = request({ url: `/tickets/${id}` })
      .then(({ data }) => {
        dispatch(ticketFetched(data));
      })
      .finally(() => {
        delete promises[id];
      });
    promises[id] = promise;
    return promise;
  };
}

export function createFollowupTicket(id) {
  return request({ url: `/tickets/${id}/followup`, method: 'POST' });
}

export function callTicket(id) {
  return request({ url: `/tickets/${id}/call`, method: 'POST' });
}

export function joinTicket(id) {
  return request({ url: `/tickets/${id}/join`, method: 'POST' });
}

export function unjoinTicket(id) {
  return request({ url: `/tickets/${id}/unjoin`, method: 'POST' });
}

export function openTicket(id) {
  return request({ url: `/tickets/${id}/open`, method: 'POST' });
}

export function cancelTicket(id) {
  return request({ url: `/tickets/${id}/cancel`, method: 'POST' });
}

export function closeTicket(id) {
  return request({ url: `/tickets/${id}/close`, method: 'POST' });
}

export function createTicket({ courseId, isFollowup, answers }) {
  return request({
    url: '/tickets',
    method: 'POST',
    data: { courseId, isFollowup, answers },
  });
}

export function sendFeedback({ ticketId, answers }) {
  return request({
    url: `/tickets/${ticketId}/feedback`,
    method: 'POST',
    data: { answers },
  });
}
