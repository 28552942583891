import React, { useState, useEffect, useCallback } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';

import SubmitIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';

import { QuizQuestionForm } from './quiz-dialog-helpers';
import useScreenSize from 'hooks/use-screen-size';
import { useCourse } from 'selectors/course-selectors';
import { createTicket } from 'actions/ticket-actions';

export default function PreInteractionDialog({
  courseId,
  isOpen,
  onClose,
  ...params
}) {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [isFollowup, setIsFollowup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const course = useCourse(courseId, {});
  const screenSize = useScreenSize();

  const isMobile = screenSize === 'xs';
  const questions = course.prompts || [];
  const numSteps = questions.length;
  const question = questions[step];
  const { answer, isValid } = answers[step] || {};
  const isShowingQuiz = step < questions.length;

  const handleNext = useCallback(() => {
    setStep((s) => s + 1);
  }, []);

  const handleBack = useCallback(() => {
    setStep((s) => s - 1);
  }, []);

  const handleChange = useCallback(
    (answer, isValid) => {
      setAnswers((answers) => {
        answers = [...answers];
        answers[step] = { question: questions[step], answer, isValid };
        return answers;
      });
    },
    [questions, step]
  );

  const handleClose = useCallback(() => {
    if (onClose instanceof Function) {
      setStep(0);
      setIsFollowup(false);
      setIsSubmitting(false);
      setAnswers([]);
      onClose();
    }
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
  }, []);

  const handleCheckboxChange = useCallback((event) => {
    setIsFollowup(event.target.checked);
  }, []);

  useEffect(() => {
    if (!isSubmitting) {
      return;
    }
    let isActive = true;
    createTicket({ courseId, isFollowup, answers })
      .then(() => {
        isActive && handleClose();
      })
      .finally(() => {
        isActive && setIsSubmitting(false);
      });
    return () => {
      isActive = false;
    };
  }, [isSubmitting, courseId, handleClose, isFollowup, answers]);

  return (
    <Dialog
      fullWidth
      scroll="paper"
      fullScreen={isMobile}
      open={isOpen}
      {...params}
    >
      <DialogTitle disableTypography>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <CircularProgress
              variant="static"
              value={(step / numSteps) * 100}
              color="secondary"
            />
          </Grid>

          <Grid item xs>
            <Typography variant="subtitle1">
              {questions.length === 0 && 'Get in-person office hours help'}
              {questions.length === 1 &&
                'Answer this question to get in-person office hours help'}
              {questions.length > 1 &&
                'Answer these questions to get in-person office hours help'}
            </Typography>
            <Typography variant="subtitle2">
              {isShowingQuiz
                ? `Question ${step + 1} of ${numSteps}`
                : 'Ready to join waitlist'}
            </Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      {isShowingQuiz ? (
        <QuizQuestionForm
          question={question}
          answer={answer}
          onChange={handleChange}
          onSubmit={handleNext}
          isSubmitDisabled={question.isRequired && !isValid}
        />
      ) : (
        <DialogContent>
          <Box mb={5}>
            <Typography variant="h5" align="center">
              You are about to be added to the waitlist
            </Typography>
          </Box>

          <Box mb={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFollowup}
                  onChange={handleCheckboxChange}
                  color="secondary"
                />
              }
              label="This question is a follow-up to something else I asked about in office hours today"
            />
          </Box>
        </DialogContent>
      )}

      <MobileStepper
        variant={isMobile ? 'dots' : 'progress'}
        steps={numSteps + 1}
        position="static"
        activeStep={step}
        style={{ backgroundColor: 'white' }}
        backButton={
          <Button
            onClick={handleBack}
            disabled={step === 0 || isSubmitting}
            color="primary"
            startIcon={<BackIcon />}
          >
            Back
          </Button>
        }
        nextButton={
          <Button
            onClick={isShowingQuiz ? handleNext : handleSubmit}
            disabled={
              (isShowingQuiz && question.isRequired && !isValid) || isSubmitting
            }
            color="primary"
            variant="contained"
            endIcon={
              isSubmitting ? (
                <CircularProgress size="1em" color="inherit" />
              ) : isShowingQuiz ? (
                <NextIcon />
              ) : (
                <SubmitIcon />
              )
            }
          >
            {isShowingQuiz ? 'Next' : 'Add me'}
          </Button>
        }
      />
    </Dialog>
  );
}
