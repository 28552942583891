/**
 * Usage:
 *   import useHand from 'hooks/use-hand';
 *   const { status, ticket } = useHand(courseId);
 *
 * - valid values for status: ['down, 'waiting', 'calling', 'helping']
 * - ticket is present if status is 'waiting', 'calling', or 'helping'
 */

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getActiveTickets } from 'selectors/ticket-selectors';
import { getSelf } from 'selectors/auth-selectors';

const getMyActiveStudentTickets = createSelector(
  getActiveTickets,
  getSelf,
  (tickets, account) =>
    tickets.filter(
      (t) =>
        account &&
        t.students.some(
          (s) => s.accountId === account.id && s.status === 'active'
        )
    )
);

export default function useHand(courseId) {
  const tickets = useSelector((state) =>
    getMyActiveStudentTickets(state, courseId)
  );
  if (tickets.length === 0) {
    return { status: 'down' };
  } else {
    const ticket = tickets[0];
    return { status: ticket.status, ticket };
  }
}
