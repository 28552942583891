import React from 'react';
import { makeStyles } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AttachmentIcon from '@material-ui/icons/Attachment';
import FollowupIcon from '@material-ui/icons/FileCopy';

import FollowupRow from './followup-row';
import QuestionPrompt from './question-prompt';

const useStyles = makeStyles(() => ({
  details: {
    alignItems: 'flex-start',
  },
}));

export default function TicketDetails({ ticket }) {
  const classes = useStyles();

  if (!ticket) {
    return null;
  }

  return (
    <List>
      {Array.isArray(ticket.prompts) && ticket.prompts.length > 0 && (
        <ListItem className={classes.details}>
          <ListItemIcon>
            <AttachmentIcon color="primary" />
          </ListItemIcon>

          <ListItemText>
            {ticket.prompts.map((p) => (
              <QuestionPrompt key={p.question.prompt} {...p} />
            ))}
          </ListItemText>
        </ListItem>
      )}

      {ticket.isFollowup && (
        <ListItem className={classes.details}>
          <ListItemIcon>
            <FollowupIcon color="primary" />
          </ListItemIcon>

          <ListItemText>
            <FollowupRow parentId={ticket.parentId} />
          </ListItemText>
        </ListItem>
      )}
    </List>
  );
}
