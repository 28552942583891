import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'redux-orm';
import orm from 'models/orm';

import { fetchUser } from 'actions/user-actions';

export const getUsers = createSelector(orm.User);

export function useUser(id) {
  const dispatch = useDispatch();
  const user = useSelector((store) => getUsers(store, id));
  if (Number.isInteger(id) && !user) {
    dispatch(fetchUser(id));
  }
  return user;
}

export function useUsers(ids) {
  const dispatch = useDispatch();
  const users = useSelector((store) => getUsers(store, ids));
  if (Array.isArray(ids)) {
    ids.forEach((id, i) => {
      if (!users[i]) {
        dispatch(fetchUser(id));
      }
    });
  }
  return users;
}

const getCourseUserId = createSelector(orm.Course, (course) => course.userId);

export const useAccountUser = (courseId) => {
  const userId = useSelector((store) => getCourseUserId(store, courseId));
  return useUser(userId);
};
