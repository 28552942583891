import React from 'react';
import { useParams, Redirect } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import AppBar from 'components/app-bar/app-bar';

import ActiveSection from './active-section';
import WaitingSection from './waiting-section';
import ShiftSection from './shift-section/shift-section';
import OfficeHoursMenu from '../menu/office-hours-menu';

export default function WaitlistPage({ role }) {
  const params = useParams();
  const courseId = Number(params.courseId);

  if (!['staff', 'instructor'].includes(role)) {
    return <Redirect to="/" />;
  }

  return (
    <AppBar
      title="Office hours waitlist"
      menu={<OfficeHoursMenu courseId={courseId} />}
    >
      <Box p={2}>
        <ShiftSection courseId={courseId} />

        <ActiveSection courseId={courseId} />

        <WaitingSection courseId={courseId} />
      </Box>
    </AppBar>
  );
}
