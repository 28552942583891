import React from 'react';
import { Redirect } from 'react-router-dom';
import { useIsAuthenticated } from 'selectors/auth-selectors';
import SignupDialog from './signup-dialog';

export default function SignupPage() {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated && window.location.pathname === '/signup') {
    return <Redirect to="/" />;
  } else {
    return <SignupDialog open />;
  }
}
