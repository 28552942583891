import React from 'react';
import WaitingTicketCard from './ticket-card-waiting';
import CallingTicketCard from './ticket-card-calling';
import HelpingTicketCard from './ticket-card-helping';
import CompletedTicketCard from './ticket-card-completed';

export default function TicketCard({ ticket, onClick }) {
  switch (ticket.status) {
    case 'waiting':
      return <WaitingTicketCard ticket={ticket} onClick={onClick} />;
    case 'calling':
      return <CallingTicketCard ticket={ticket} onClick={onClick} />;
    case 'helping':
      return <HelpingTicketCard ticket={ticket} />;
    case 'completed':
      return <CompletedTicketCard ticket={ticket} />;
    default:
      return null;
  }
}
