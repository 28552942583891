import React from 'react';
import Section from 'components/content/section';
import TicketCard from './ticket-card/ticket-card';
import { useMyHelpingTickets } from 'selectors/ticket-selectors';

export default function ActiveSection({ courseId }) {
  const tickets = useMyHelpingTickets(courseId);

  if (Array.isArray(tickets) && tickets.length > 0) {
    return (
      <Section title={`Currently helping (${tickets.length})`}>
        {tickets.map((ticket) => (
          <TicketCard key={ticket.id} ticket={ticket} />
        ))}
      </Section>
    );
  } else {
    return null;
  }
}
