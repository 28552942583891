import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import SuccessIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import LoadingIcon from '@material-ui/icons/MoreHoriz';

import { confirmEmail } from 'actions/email-actions';
import emailRegex from 'utils/email-regex';

export default function ConfirmContent({ email, token }) {
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (!emailRegex.test(email)) {
      setResult(400);
      return;
    }
    let isActive = true;
    setResult(null);
    confirmEmail({ email, token }).then(({ status }) => {
      isActive && setResult(status);
    });
    return () => {
      isActive = false;
    };
  }, [email, token]);

  switch (result) {
    case null: // loading
      return (
        <>
          <Box mb={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar>
                  <LoadingIcon />
                </Avatar>
              </Grid>
              <Grid item xs>
                <Typography variant="h6">Confirming email</Typography>
              </Grid>
            </Grid>
          </Box>

          <Typography paragraph>
            Please wait while your email address {email} is being confirmed.
          </Typography>
        </>
      );

    case 200: // success
      return (
        <>
          <Box mb={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar>
                  <SuccessIcon />
                </Avatar>
              </Grid>
              <Grid item xs>
                <Typography variant="h6">Email confirmed</Typography>
              </Grid>
            </Grid>
          </Box>

          <Typography paragraph>
            Your email address, <strong>{email}</strong>, is now confirmed with
            My Digital Hand.
          </Typography>

          <Box textAlign="right">
            <Typography>
              <Button
                component={Link}
                color="primary"
                variant="contained"
                to="/"
                startIcon={<SuccessIcon />}
              >
                Great!
              </Button>
            </Typography>
          </Box>
        </>
      );

    case 409: // expired
    case 422: // invalid
      return (
        <>
          <Box mb={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar>
                  <ErrorIcon />
                </Avatar>
              </Grid>
              <Grid item xs>
                <Typography variant="h6">Expired confirmation link</Typography>
              </Grid>
            </Grid>
          </Box>

          <Typography paragraph>
            Confirmation emails expire after a few days.
          </Typography>

          <Typography paragraph>
            To confirm {email}, you&apos;ll have to{' '}
            <Link to={`/emails/lookup?email=${encodeURIComponent(email)}`}>
              resend the confirmation email
            </Link>
            .
          </Typography>

          <Typography paragraph>
            You can also try searching your inbox for a more recent confirmation
            email.
          </Typography>
        </>
      );

    case 400: // bad request
    case 404: // not found
      return (
        <>
          <Box mb={3}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar>
                  <ErrorIcon />
                </Avatar>
              </Grid>
              <Grid item xs>
                <Typography variant="h6">
                  Corrupt or invalid confirmation link
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Typography paragraph>
            Something went wrong, because this is not a valid email confirmation
            link.
          </Typography>

          <Typography paragraph>
            Try copying the link text from your confirmation email directly into
            the URL bar of your browser.
          </Typography>
        </>
      );

    default:
      return null;
  }
}
