import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import StatusBadge from './status-badge';
import { useUsers } from 'selectors/user-selectors';

// First column:  background color
// Second column: font color
const colors = [
  ['#C46210', '#ffffff'],
  ['#2E5894', '#ffffff'],
  ['#9C2542', '#ffffff'],
  ['#BF4F51', '#ffffff'],
  ['#A57164', '#ffffff'],
  ['#58427C', '#ffffff'],
  ['#4A646C', '#ffffff'],
  ['#85754E', '#ffffff'],
  ['#319177', '#ffffff'],
  ['#0A7E8C', '#ffffff'],
  ['#9C7C38', '#ffffff'],
  ['#8D4E85', '#ffffff'],
  ['#8FD400', '#ffffff'],
  ['#D98695', '#ffffff'],
  ['#757575', '#ffffff'],
  ['#0081AB', '#ffffff'],
];

export default function UserAvatar({ id, ids, max, hideBadge = false }) {
  const userIds = Number.isInteger(id) ? [id] : ids;
  const users = useUsers(userIds);

  if (users.length === 0) {
    if (!Array.isArray(userIds) || userIds.length < 2) {
      return <Avatar />;
    } else {
      return (
        <AvatarGroup max={max}>
          {userIds.map((id) => (
            <Avatar key={id} />
          ))}
        </AvatarGroup>
      );
    }
  } else if (users.length === 1) {
    if (hideBadge) {
      return UserWithoutStatusBadge(users[0]);
    } else {
      return UserWithStatusBadge(users[0]);
    }
  } else if (hideBadge) {
    return (
      <AvatarGroup max={max}>{users.map(UserWithoutStatusBadge)}</AvatarGroup>
    );
  } else {
    return (
      <AvatarGroup max={max}>{users.map(UserWithStatusBadge)}</AvatarGroup>
    );
  }
}

export function UserWithoutStatusBadge(user) {
  if (!user) {
    return <Avatar />;
  }

  const { first, last, avatar } = user;
  const initials = `${first.charAt(0)}${last.charAt(0)}`.toUpperCase();
  const hash = hashCode(initials);
  const color = colors[hash % colors.length];

  return (
    <Avatar
      alt={`${first} ${last}`}
      src={avatar}
      style={{ backgroundColor: color[0], color: color[1] }}
    >
      {initials}
    </Avatar>
  );
}

export function UserWithStatusBadge(user) {
  if (!user) {
    return <Avatar />;
  }

  const { first, last, avatar, presence } = user;
  const initials = `${first.charAt(0)}${last.charAt(0)}`.toUpperCase();
  const hash = hashCode(initials);
  const color = colors[hash % colors.length];

  return (
    <StatusBadge status={presence} hasRipple={presence !== 'offline'}>
      <Avatar
        alt={`${first} ${last}`}
        src={avatar}
        style={{ backgroundColor: color[0], color: color[1] }}
      >
        {initials}
      </Avatar>
    </StatusBadge>
  );
}

// See: https://stackoverflow.com/a/7616484
export function hashCode(str) {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0;
  }
  return hash;
}
