import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import useScreenSize from 'hooks/use-screen-size';
import TicketActions from './ticket-actions';
import TicketDetails from './ticket-details';
import { useTicket } from 'selectors/ticket-selectors';

export default function DetailsDialog({ ticketId, onClose, ...props }) {
  const ticket = useTicket(ticketId);
  const screenSize = useScreenSize();

  if (!ticket) {
    return null;
  }

  return (
    <Dialog
      open
      fullWidth
      fullScreen={screenSize === 'xs'}
      onClose={onClose}
      {...props}
    >
      <DialogTitle>Ticket details</DialogTitle>

      <DialogContent>
        <TicketDetails ticket={ticket} />
      </DialogContent>

      <DialogActions>
        <TicketActions
          ticketId={ticketId}
          status={ticket.status}
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
}
