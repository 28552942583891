import React from 'react';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

export default function RoleMenu({
  exclude,
  onSelect,
  onClose,
  anchor,
  count,
}) {
  const handleSelect = (role) => () => {
    onSelect instanceof Function && onSelect(role);
  };

  return (
    <Menu
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={onClose}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
      <MenuItem disabled>
        <Typography variant="subtitle2">Move {count} to:</Typography>
      </MenuItem>

      {exclude !== 'student' && (
        <MenuItem onClick={handleSelect('student')}>
          <Box pl={2}>Students</Box>
        </MenuItem>
      )}

      {exclude !== 'staff' && (
        <MenuItem onClick={handleSelect('staff')}>
          <Box pl={2}>Staff</Box>
        </MenuItem>
      )}

      {exclude !== 'instructor' && (
        <MenuItem onClick={handleSelect('instructor')}>
          <Box pl={2}>Instructors</Box>
        </MenuItem>
      )}
    </Menu>
  );
}
