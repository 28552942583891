import { useSelector } from 'react-redux';

export default (state) => state.auth;

// Deprecated (use getAccount/useAccount instead)
export const getSelf = (state) => state.auth?.account;
export const useSelf = () => useSelector(getSelf);

export const getAccount = (state) => state.auth?.account;
export const useAccount = () => useSelector(getSelf);

export const getIsAuthenticated = (state) => state?.auth?.isAuthenticated;
export const useIsAuthenticated = () => useSelector(getIsAuthenticated);
