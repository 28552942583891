import React from 'react';
import { makeStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import EnvelopeIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles((theme) => ({
  pending: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
  },
  invited: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function AvatarCell({ status }) {
  const classes = useStyles();

  switch (status) {
    case 'active':
      return <Avatar />;
    case 'pending':
      return (
        <Avatar className={classes.pending}>
          <EnvelopeIcon />
        </Avatar>
      );
    case 'invited':
      return (
        <Avatar className={classes.invited}>
          <EnvelopeIcon />
        </Avatar>
      );
    default:
      return null;
  }
}
