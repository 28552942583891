import request from 'api/request';

export function sendPasswordResetEmail(email) {
  return request({
    url: '/passwords/send-reset-email',
    method: 'POST',
    data: { email },
  });
}

export function resetPassword({ token, password }) {
  return request({
    url: '/passwords/reset',
    method: 'POST',
    data: { token, password },
  });
}
