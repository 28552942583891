import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

export default function NameCell({ status, first, last, email }) {
  if (Boolean(first) || Boolean(last)) {
    return (
      <>
        <Typography variant="body1">
          {first ?? ''} {last ?? ''}
        </Typography>
        <Hidden mdUp>
          <Typography variant="body2" color="textSecondary">
            {email}{' '}
            {status === 'pending' && (
              <Typography variant="body2" component="span" color="error">
                (unconfirmed)
              </Typography>
            )}
          </Typography>
        </Hidden>
      </>
    );
  } else {
    return (
      <Hidden mdUp>
        <Typography variant="body1">
          {email}{' '}
          {status === 'pending' && (
            <Typography variant="body1" component="span" color="error">
              (unconfirmed)
            </Typography>
          )}
        </Typography>
      </Hidden>
    );
  }
}
