import React, { useState, useEffect } from 'react';

import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { useCourse } from 'selectors/course-selectors';
import { updateCourse } from 'actions/course-actions';
import Section from 'components/content/section';
import useDebounced from 'hooks/use-debounced';

export default function ScheduleUrlSection({ courseId }) {
  const course = useCourse(courseId);
  const handleUpdate = useDebounced(updateCourse, 1000);
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (course.scheduleUrl) {
      setUrl(course.scheduleUrl);
    }
  }, [course.scheduleUrl]);

  const handleChange = (event) => {
    const newUrl = event.target.value;
    setUrl(newUrl);
    handleUpdate({ id: courseId, scheduleUrl: newUrl });
  };

  if (!course) {
    return null;
  }

  return (
    <Collapse in={course?.scheduleConfig === 'external'}>
      <Section title="External calendar URL">
        <Typography paragraph>
          Paste the URL to your public external calendar.
        </Typography>

        <Typography paragraph component="div">
          <TextField
            value={url}
            onChange={handleChange}
            label="External calendar link"
            variant="outlined"
            helperText="Changes are saved automatically"
            fullWidth
          />
        </Typography>
      </Section>
    </Collapse>
  );
}
